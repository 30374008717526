import {httpDelete, httpGet, httpPost} from "@webservices/index";
import {Message} from "@webservices/Message";
import {API_URL, PIZZA_API_URL} from "../../../config";
import {convertUint8ArrayToBase64} from "@utilities/binary";

export interface Favorite {
    pizzaFavoriteId: string
    userId: string
    pizzaId: string
    created_at: null|string[]
    updated_at: null|string[]
    deleted_at: null|string[]
    name: string
    image: string
    isActive: boolean
    calories: number
    weight: number
    price: number
    currency: "USD",
    ingredients: string[]
    details: string
    cookTime: number
}

export const addToFavorites = async (pizzaId:string):Promise<Favorite|undefined> => {
    console.log(`${API_URL}/${PIZZA_API_URL}pizzaFavorites`);
    const favorite = await httpPost<any, Message<Favorite>>(
        `${API_URL}/${PIZZA_API_URL}pizzaFavorites`,
        {
            userId:localStorage.getItem("sub"),
            pizzaId
        }
    )
    return favorite?.data
}

export const removeFromFavorites = async (id:string):Promise<void> => {
    await httpDelete<Message<Favorite>>(
        `${API_URL}/${PIZZA_API_URL}pizzaFavorites/${id}`
    )
}

export const getFavorites = async ():Promise<Favorite[]> => {
    const favorites = await httpGet<Message<Favorite[]>>(
        `${API_URL}/${PIZZA_API_URL}pizzaFavorites/${localStorage.getItem("sub")}`,
    )
    if(favorites?.data) {
        let favoriteList:any = [];
        favorites.data.forEach(element => {
            favoriteList.push({pizzaId: element.pizzaId, pizzaFavoriteId: element.pizzaFavoriteId});
        });
        
        localStorage.setItem("favorites", JSON.stringify(favoriteList));
        return favorites.data;
    }
    return [];
}