import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import Icon from "@components/common/icon";
import {Add, Minus} from "@components/common/svgIcons";
import {GlobalContext} from "../../../contexts/GlobalContext";

interface AddToCartButtonProps {
    id:string
    title:string
    price:number
    poster:string
    initialValue?:number
    transparent?:boolean
}

const AddToCartButton:FunctionComponent<AddToCartButtonProps> = (props) => {

    const [count, setCount] = useState<number>(props.initialValue || 0);

    const {cart, addToCart} = useContext(GlobalContext);

    useEffect(()=>{
        if(cart.length < 1) return;
        const index = cart.find(item=>item.id === props.id);
        if(index){
            setCount(index.count);
        }
    }, [props])

    useEffect(()=>{
        addToCart!({
            id: props.id,
            title:props.title,
            count: count,
            price: props.price,
            poster: props.poster
        });
    }, [count])

    return (
        <>
            {
                count === 0 ?
                    <div
                        className={`${!props.transparent ? "primary-gradient" : ""} p-2 rounded-5`}
                        onClick={(e)=>{
                            e.stopPropagation();
                            setCount(prevState => prevState+1);
                        }}
                    >
                        <Icon size={20} color={"black"} icon={<Add/>} />
                    </div> :
                    <div className={`d-flex ${!props.transparent ? "primary-gradient" : ""} align-items-center p-2 rounded-5`}>
                        <div
                            className={"px-2"}
                            onClick={(e)=>{
                                e.stopPropagation();
                                setCount(prevState => prevState-1);
                            }}
                        >
                            <Icon size={20} color={"black"} icon={<Minus/>}/>
                        </div>
                        <p className={"bold f-s-4"}>{count}</p>
                        <div
                            className={"px-2"}
                            onClick={(e)=>{
                                e.stopPropagation();
                                setCount(prevState => prevState+1);
                            }}
                        >
                            <Icon size={20} color={"black"} icon={<Add/>} />
                        </div>
                    </div>
            }
        </>
    );
};

export default AddToCartButton;