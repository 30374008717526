import ERC1155 from "../../constant/contractAbi/ERC1155.json";
import Web3 from "web3";
import { CONTRACT_ADDRESS, OPERATOR_ADDRESS } from "config";

export const setApprovalForAll = async (account:string):Promise<boolean> => {
    const web3 = new Web3(window.ethereum);
    const contract = new web3.eth.Contract(ERC1155.abi, CONTRACT_ADDRESS);
    let data;
    // @ts-ignore
    data = contract.methods.setApprovalForAll(OPERATOR_ADDRESS, true).encodeABI();
    const count = await web3.eth.getTransactionCount(account,'pending');
    let gasPrice = await web3.eth.getGasPrice();
    try {
        const res = await web3.eth.sendTransaction({
            from:account,
            data:data,
            value: 0,
            gasPrice: gasPrice,
            gasLimit: web3.utils.numberToHex('50000'),
            nonce:count,
            to: CONTRACT_ADDRESS,
        });
        console.log(res);
        return true;
    } catch (e) {
        console.log("test: ", e);
        return false;
    }
}

export const isApproved = async (accounts:string):Promise<boolean> => {
    if(window.ethereum){
        const web3 = new Web3(window.ethereum);
        const contract = new web3.eth.Contract(ERC1155.abi, CONTRACT_ADDRESS);
        // @ts-ignore
        return  await contract.methods.isApprovedForAll(accounts, OPERATOR_ADDRESS).call();
    }
    return false;
}
