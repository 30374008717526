import React, {useState} from 'react';
import LoginImage from '@assets/images/login.svg';
import Text from "@components/common/text";
import Input from "@components/common/form/input";
import CheckBox from "@components/common/form/checkBox";
import CustomButton from "@components/common/customButton";
import Icon from "@components/common/icon";
import {Envelop, Loading} from "@components/common/svgIcons";
import Divider from "@components/common/divider";
import AuthMethods from "@components/app/authMethods";
import {getAuth, sendSignInLinkToEmail} from "firebase/auth";
import {handleFirebaseError} from "../../services/firebaseErrorHandler";
import {useHistory} from "react-router-dom";
import Joi from "joi";
import {failToast, successToast} from "@toast/index";
import { HOME_URL } from 'config';

const Login = () => {

    const history = useHistory();
    const query = new URLSearchParams(history.location.search);

    const [email, setEmail] = useState<string>("")
    const [loginLoading, setLoginLoading] = useState<boolean>(false)

    const emailActionCodeSettings = {
        url: `${HOME_URL}verify-email${query.get("ref")  ? `?ref=${query.get("ref")}` : ""}`,
        handleCodeInApp: true
    };

    const login = async () => {
        try{
            const emailValidation = Joi.string().email({tlds:{allow: false}}).required().validate(email);
            if(emailValidation.error){
                failToast(<Text value={"email address you entered is invalid"} class={"capitalize"}/>);
                return;
            }
            setLoginLoading(true);
            const auth = getAuth();
            await sendSignInLinkToEmail(auth, email, emailActionCodeSettings);
            localStorage.setItem("loggedInEmail", email);
            successToast(<Text value={"check your email to find activation link."} class={"capitalize"}/>);
            history.push("/")
        } catch (error) {
            handleFirebaseError(error);
            setLoginLoading(false);
        }
    }

    return (
        <div className={"d-flex h-100 flex-column p-4"}>
            <div className={"d-flex flex-column justify-content-center align-items-center flex-grow-1"}>
                <img src={LoginImage} alt={"login image"}/>
                <h1 className={"bold primary-gradient-text f-s-6 text-center my-3"}><Text value={"create an account"} class={"capitalize"}/></h1>
                <div className={"w-100"}>
                    <Input
                        label={"email"}
                        placeHolder={"enter your email"}
                        onChange={(id, value)=>{setEmail(value||"")}}
                        preIcon={<Icon size={25} color={"none"} icon={<Envelop/>} gradient/>}
                    />
                </div>
                <CheckBox
                    onChange={()=>{}}
                    options={[{title:"i agree to the Privacy Policy and Agreement", value:"1"}]}
                    className={"my-3"}
                />
                <div>
                    <CustomButton
                        theme={"primary-gradient"}
                        text={<Text value={"login"} class={"capitalize"}/>}
                        className={"px-5 mt-3"}
                        onClick={login}
                        loading={loginLoading}
                        loadingIcon={<Icon size={20} color={"none"} icon={<Loading/>}/>}
                    />
                </div>
            </div>
            <div className={"d-flex flex-column justify-content-center align-items-center "}>
                <div className={"d-flex w-75 gap-3 align-items-center"}>
                    <Divider/>
                    <p><Text value={"or"} class={"capitalize"}/></p>
                    <Divider/>
                </div>
                <AuthMethods
                    gmail
                    metaMask
                    connectWallet
                />
            </div>
        </div>
    );
};

export default Login;