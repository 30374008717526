import React, { ReactNode, useEffect, useState } from 'react';
import Icon from "@components/common/icon";
import Text from "@components/common/text";
import styles from "./discount.module.scss";
import { useHistory } from "react-router-dom";
import { PersonalInfo as Person, Back, CopyRedeemCode } from "@components/common/svgIcons";
import { getUserVouchersByUid, UserVouchers } from "@webservices/user";
import CopyClipboard from '@components/common/copyClipboard';
import VoucherPZZA from '../../../src/assets/images/voucherPizza.png';


const Discount = () => {

    const [vouchers, setVouchers] = useState<UserVouchers[]>([]);

    const history = useHistory();

    useEffect(() => {
        (async () => {
            const userVouchers = await getUserVouchersByUid();
            setVouchers(userVouchers);
        })()
    }, [])

    const generateVoucherList = () => {
        return (            
            vouchers.filter(item => item.state === 1).map((item) => {                
                return(
                    <div className={`${styles.couponContainer} ${styles.bump}`}>
                        <div className={styles.dataContainer}>
                            <div className={styles.imageContainer}>
                                <img src={VoucherPZZA} className={styles.generalPizza} alt={VoucherPZZA}/>
                            </div>
                            <div className={styles.detailsContainer}>
                                <Text value={"Your discount on next order"} class={"capitalize"} />
                                <div className={styles.detailsBottom}>
                                    <div className={styles.discount}>
                                        <Text value={""} class={"capitalize"} />
                                    </div>
                                    <div className={styles.redeemCode}>
                                        <CopyClipboard text={item.code} />
                                        <Icon size={24} color={"light-gray"} icon={<CopyRedeemCode />} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        );
    }

    return (
        <>
            <div className={"d-flex justify-content-around flex-wrap w-100 p-3 h-100 overflow-auto"} style={{ alignItems: 'center' }}>
                <div className={styles.discountHeader}>
                    <div onClick={() => {
                        history.push('/profile');
                    }}>
                        <Icon size={24} color={"none"} icon={<Back />} gradient />
                    </div>
                    <h1 className={"bold primary-gradient-text f-s-6 text-center my-3"}>
                        <Text value={"Discount"} class={"capitalize"} />
                    </h1>
                    <div></div>
                </div>
                <div className={"d-flex h-100"} style={{flexDirection: 'column'}}>
                {
                    vouchers.filter(item => item.state === 1).length === 0 ?
                            <div className={styles.noDiscount}>
                                <Text value={"You have not received any discount codes yet!"} class={"capitalize"} />
                            </div>                                                    
                    : generateVoucherList()
                }
                </div>
            </div>
        </>
    );
};


export default Discount;