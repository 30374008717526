import React, {FunctionComponent, useEffect, useMemo, useState} from 'react';
import HorizontalSlider from "@components/basic/horizontalSlider";
import {slices} from "../../../constant/slices";
import {groupBy, moveItemToFirst} from "@utilities/array";
import Icon from "@components/common/icon";
import {Lock} from "@components/common/svgIcons";
import {Slice as SliceType} from "@types.d/Slice";
import {getUserSlices, UserSlice} from "@webservices/slices";

interface UserSlicesProps {
    onSelect:(id:number, status:"lock"|"unlock")=>void
    decreaseOnSelect?:boolean
    release?:{id:number, amount:number, actionId:string}
    disabled?: boolean
}

const UserSlices:FunctionComponent<UserSlicesProps> = (props) => {

    const [userSlices, setUserSlices] = useState<SliceType[]>([]);

    useEffect(()=>{
        (async ()=>{
            const result = await getUserSlices();
            const groupedResult = groupSlices(result);
            const sortedResult = groupedResult.sort((a, b) => b.amount - a.amount).reverse();
            setUserSlices(sortedResult);//.map(res => ({ id:res.id, amount:res.amount, sliceId: res.sliceId})));
        })()
    }, [])
    
    useEffect(()=>{
        if(!props.release?.id) return;
        setUserSlices(prevState => {
            const temp = [...userSlices];
            const index = temp.findIndex(slice=>slice.id === props.release!.id);
            temp[index] = {id: props.release!.id, amount:temp[index].amount+props.release!.amount};
            return temp;
        })
    }, [props.release])

    const groupSlices = (array:UserSlice[]):SliceType[] => {
        const convertedArray:{[K:string]:any} = [...array];
        let sliceTypeArray:SliceType[] = [];
        array.map(item => {
            const index = sliceTypeArray.findIndex(slice => slice.id === item.sliceTypeId);
            if(index === -1){
                sliceTypeArray.push({id: item.sliceTypeId, amount: 1});
            }
            else{
                sliceTypeArray[index].amount++;
            }
        })
        return sliceTypeArray;
    }

    const handleSelect = (id: number, status: "lock" | "unlock") => {
        if (!props.disabled) {
            props.onSelect(id, status);
            if (props.decreaseOnSelect && status === "unlock") {
                setUserSlices(prevState => {
                    const temp = [...userSlices];
                    const index = temp.findIndex(slice => slice.id === id);
                    temp[index] = { id, amount: temp[index].amount - 1 };
                    return temp;
                })
            }
        }
    }

    const getAllSlices = ():any[] => {
        // sort slices indexes by users owns items
        let sortedSlices = [...slices];
        userSlices.map(slice=>{
            const sIndex = sortedSlices.findIndex(s => slice.id === s.id);
            moveItemToFirst(sortedSlices, sIndex);
        });
        // end sorting
        let array = [];
        for(let i=0; i<sortedSlices.length; i+=2){
            const current = userSlices.find(us=>us.id === sortedSlices[i].id);
            const next = userSlices.find(us=>us.id === sortedSlices[i+1].id);
            array.push(<div>
                <div
                    className={"position-relative d-flex flex-column align-items-center mb-2"}
                    onClick={()=>handleSelect(sortedSlices[i].id, (current && current.amount>0) ? "unlock" : "lock")}
                >
                    <img
                        className={`w-50 ${(!current || current.amount <= 0) && "opacity-50"}`}
                        src={sortedSlices[i].icon}
                        alt={sortedSlices[i].title}
                    />
                    <p>x{current?.amount || 0}</p>
                    {
                        (!current || current.amount <= 0) && <div className={"position-absolute"}>
                            <Icon size={20} color={"none"} icon={<Lock/>} />
                        </div>
                    }
                </div>
                <div
                    className={"position-relative d-flex flex-column align-items-center"}
                    onClick={()=>handleSelect(sortedSlices[i+1].id, (next && next.amount>0) ? "unlock" : "lock")}
                >
                    <img
                        className={`w-50 ${(!next || next.amount <= 0) && "opacity-50"}`}
                        src={sortedSlices[i+1].icon}
                        alt={sortedSlices[i+1].title}
                    />
                    <p>x{next?.amount || 0}</p>
                    {
                        (!next || next.amount <= 0) && <div className={"position-absolute"}>
                            <Icon size={20} color={"none"} icon={<Lock/>} />
                        </div>
                    }
                </div>
            </div>)
        }
        return array;
    }

    const slicesElements = useMemo(()=>{
        return getAllSlices();
    }, [userSlices])

    return (
        <HorizontalSlider
            slides={slicesElements}
            slideWidth={60}
        />
    );
};

export default UserSlices;