import {httpGet, httpPost} from "@webservices/index";
import {API_URL, DEF_BRANCH, PIZZA_API_URL} from "config";
import {Message} from "@webservices/Message";
import {ApiError} from "@types.d/ApiError";

export interface Order {
    orderId: string
    userId: string
    branchId: string
    voucherCode: null|string
    discount: number
    totalPrice: number
    cooked: boolean
    customerRate: number
    orderStatus: number
    customerComment: null|string
    created_at: string
    updated_at: string
    deleted_at: string
}

export const createOrder = async(order:any, voucherCode:string|undefined, onError?:(err:ApiError)=>void):Promise<Order|undefined> => {
    const createdOrder = await httpPost<any, Message<Order>>(
        `${API_URL}/${PIZZA_API_URL}orders`,
        {
            ...order,
            voucherCode,
            userId:localStorage.getItem("sub"),
            branchId: DEF_BRANCH
        },
        undefined,
        onError
    )
    return createdOrder?.data;
}

export const getOrders = async(onError?:(err:ApiError)=>void):Promise<Order[]> => {
    const orders = await httpGet<Message<Order[]>>(
        `${API_URL}/${PIZZA_API_URL}orders/byUserId/${localStorage.getItem("sub")}`,
        undefined,
        onError
    )
    return orders?.data || [];
}

export const getOrderById = async(orderId:string, onError?:(err:ApiError)=>void):Promise<Order|undefined> => {
    const orders = await httpGet<Message<Order>>(
        `${API_URL}/${PIZZA_API_URL}orders/${orderId}`,
        undefined,
        onError
    )
    return orders?.data;
}