import {ApiError} from "@types.d/ApiError";
import {httpPost} from "@webservices/index";
import {Message} from "@webservices/Message";
import {API_URL, PIZZA_API_URL} from "../../../config";
import {Order} from "@webservices/order";

export interface Payment {
    userId: string
    orderId: string
    paymentGateway: string
    paymentRef: string
    value: number
    tax: number
}

export const createPayment = async(order:Order, onError?:(err:ApiError)=>void):Promise<any|undefined> => {
    const createPayment = await httpPost<any, Message<any>>(
        `${API_URL}/${PIZZA_API_URL}payments`,
        {
            userId: localStorage.getItem("sub"),
            orderId: order.orderId,
            paymentGateway: "googlePay",
            paymentRef: "REF12346",
            value: order.totalPrice,
            tax: 0
        },
        undefined,
        onError
    )
    return createPayment?.data;
}