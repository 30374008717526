import React, {useEffect, useState} from 'react';
import styles from "./slices.module.scss";
import Header from "@components/layouts/layoutElements/header";
import Icon from "@components/common/icon";
import {Ethereum, Gift, GreenLeaves, Heart, Loading, Slice, Warning} from "@components/common/svgIcons";
import Modal from "@components/common/modal";
import CustomButton from "@components/common/customButton";
import Text from "@components/common/text";
import SlicePreview from "@components/modals/slicePreview";
import {useHistory} from "react-router-dom";
import BottomSheet from "@components/common/bottomSheet";
import SelectSlice from "@components/bottomSheets/selectSlice";
import UserSlices from "@components/app/userSlices";
import {getUserInfo} from "@webservices/user";
import ConnectWallet from "@components/modals/connectWallet";
import ConfirmModal from '@components/modals/confirmModal';
import RedeemPizza from '@components/modals/redeemPizza';
import { getFreePizza } from '@webservices/slices';
import {Slice as SliceType} from "@types.d/Slice";
import Confetti from "@assets/images/confetti.svg";
import { OPENSEA_BASE_URL } from 'config';
import {failToast, successToast} from '@toast/index';
import {isApproved, setApprovalForAll} from "../../services/app/approveItemTransfer";
import Web3 from 'web3';

const Slices = () => {

    const history = useHistory();

    const [selectedSlice, setSelectedSlice] = useState<number>();    
    const [selectedSlicesBT, setSelectedSliceBT] = useState<boolean>(false);
    const [showWalletConnectModal, setShowConnectWalletModal] = useState<boolean>(false);
    const [walletId, setWalletID] = useState<string|undefined>(undefined);
    const [confirmFreePizza, setConfirmFreePizza] = useState<boolean>(false);
    const [showRedeem, setShowRedeem] = useState<boolean>(false);
    const [redeemingSlices, setRedeemingSlices] = useState<SliceType[]>([]);
    const [redeemCode, setRedeemCode] = useState<string|undefined>();
    const [requiredSliceCount, setRequiredSliceCount] = useState<number>();
    const [showApproval, setShowApproval] = useState<boolean>(false);
    const [approvalLoading, setApprovalLoading] = useState<boolean>(false);

    useEffect(()=>{
        (async () =>{
            const user = await getUserInfo();
            setWalletID(user?.walletId || "0x0");
        })()
    }, [])

    useEffect(()=>{
        (async () =>{
            if(walletId && walletId !== "0x0"){
                const res= await isApproved(walletId);
                if(!res){
                    setShowApproval(true);
                }
            }
        })()

    }, [walletId])

    const getWalletBalance = async ():Promise<boolean> => {
        // Check if Web3 is available
        if (typeof window.ethereum !== 'undefined') {
            const web3 = new Web3(window.ethereum);
            try {
                const balance = await window.ethereum.request({method: 'eth_getBalance', params: [walletId, 'latest']});
                const convertedBalance = web3.utils.fromWei(web3.utils.hexToNumber(balance), "ether");
                if(Number(convertedBalance) < 0.0001){
                    failToast(<Text value={"You must have enough matic in your wallet to approve."} class={"capitalize"} />);
                    return false;
                }
                return true;
            } catch (error) {
                console.error(error);
                return false;
            }
        } else {
            failToast(<Text value={'Web3 provider not detected. Please install Metamask or enable it.'}  class={'capitalize'}/>);
            return false;
        }
    }

    const approveAll = async () => {
        const balance = await getWalletBalance();
        if(!balance) return;
        setApprovalLoading(true)
        if(walletId) {
            const res = await setApprovalForAll(walletId);
            if(res) {
                setShowApproval(false);
                successToast(<Text value={"Approve Done"} class={"capitalize"} />);
            } else {
                failToast(<Text value={"Approve Failed"} class={"capitalize"} />);
            }
        }
        setApprovalLoading(false)
    }

    const freePizza = async () => {
        await getFreePizza(redeemingSlices).then((releaseResult) => {
            setRedeemCode(releaseResult?.code);
            setConfirmFreePizza(false);
            setShowRedeem(true);
        });
    }

    const sellNFTSlices = async () => {
        const userInfo = await getUserInfo();
        window.open(`${OPENSEA_BASE_URL}` + userInfo?.walletId);
    }

    return (
        <>
            <Header
                title={"my slices"}
                menu
            />
            <div className={"d-flex flex-column align-items-center px-4 flex-1 overflow-auto pb-5"}>
                {
                    walletId === "0x0" && <div className={"d-flex flex-column mb-3"}>
                        <div className={"d-flex gap-2"}>
                            <Icon size={30} color={"none"} icon={<Warning/>} gradient />
                            <p>To  sell  or invest your items, you need to connect your Metamask wallet. </p>
                        </div>
                        <CustomButton
                            theme={"no-border"}
                            textColor={"primary"}
                            className={"text-decoration-underline"}
                            text={"Connect now"}
                            onClick={()=>{setShowConnectWalletModal(true)}}
                        />
                    </div>
                }
                {
                    (walletId !== "0x0" && showApproval) && <div className={"d-flex flex-column mb-3"}>
                        <CustomButton
                            theme={"secondary"}
                            text={"Approve PZZA to Trade Your NFTs"}
                            onClick={approveAll}
                            loading={approvalLoading}
                            loadingIcon={<Icon size={20} color={"none"} icon={<Loading/>}/>}
                        />
                    </div>
                }
                <div className={"d-flex flex-column w-100 align-items-center"}>
                    <UserSlices
                        onSelect={setSelectedSlice}
                    />
                    <CustomButton   
                        theme={"primary-gradient"}
                        text={<Text value={"sell NFT slices"} class={"capitalize"}/>}
                        className={"my-3"}
                        icon={<Icon size={20} color={"none"} icon={<Ethereum/>} />}
                        onClick={() => {
                            sellNFTSlices();
                        }}
                    />
                </div>
                <div
                    className={"d-flex flex-column w-100 align-items-center cms-back-glass p-4 rounded-4"}
                    style={(!walletId || walletId === "0x0") ? {opacity:0.5, pointerEvents:"none"} : {}}
                >
                    <p className={"bold mb-3"}>You need {requiredSliceCount} more slice to unlock a pattern</p>
                    <CustomButton
                        theme={"violet-gradient"}
                        text={<Text value={"invest slices"} class={"capitalize"}/>}
                        onClick={()=>history.push("/invest")}
                    />
                    <CustomButton
                        theme={"no-border"}
                        textColor={"black"}
                        text={<Text value={"Explore my investment"} class={"capitalize"}/>}
                        className={"text-decoration-underline"}
                        onClick={()=>history.push("/investments")}
                    />
                </div>
                <h1 className={"primary-gradient-text mt-3 w-100 text-start"}>Use your slices for </h1>
                <div className={styles.sliceUsage}>
                    <div className={styles.sliceUsageButton}
                        onClick={() => history.push("/buy-gift")}>
                        <Icon size={25} color={"blue"} icon={<Gift />} />
                        <p className={"f-s-2 mt-1"}>Gift</p>
                    </div>
                    <div className={styles.sliceUsageButton}
                        onClick={() => history.push("/donate")}>
                        <Icon size={25} color={"red"} icon={<Heart />} />
                        <p className={"f-s-2 mt-1"}>Donate</p>
                    </div>
                    <div className={styles.sliceUsageButton}
                        onClick={() => {setSelectedSliceBT(true)}}>
                        <Icon size={25} color={"primary"} icon={<Slice />} />
                        <p className={"f-s-2 mt-1"}>Free Pizza</p>
                    </div>
                    <div
                        className={styles.sliceUsageButton}
                        onClick={() => {                            
                            successToast("Comming soon, check back soon to get Carbon Credit!");
                        }}
                        style={(!walletId || walletId === "0x0") ? { opacity: 0.5, pointerEvents: "none" } : {}}
                    >
                        <Icon size={25} color={"green"} icon={<GreenLeaves />} />
                        <p className={"f-s-2 mt-1"}>Carbon Credit</p>
                    </div>
                </div>
            </div>
            {
                selectedSlice && <Modal
                    onClose={()=>setSelectedSlice(undefined)}
                    align={"center"}
                    closeOnOutSideClick
                >
                    <SlicePreview
                        sliceId={selectedSlice}
                        onClose={()=>setSelectedSlice(undefined)}
                    />
                </Modal>
            }
            {
                showWalletConnectModal && <Modal
                    onClose={()=>setShowConnectWalletModal(false)}
                    align={"center"}
                    closeOnOutSideClick
                >
                    <ConnectWallet
                        onClose={()=>setShowConnectWalletModal(false)}
                        onSubmit={(address)=>{
                            setWalletID(address);
                            setShowConnectWalletModal(false);
                            successToast("Wallet addrss, registered!");
                        }}
                    />
                </Modal>
            }
            {
                selectedSlicesBT && 
                <BottomSheet onClose={()=>setSelectedSliceBT(false)}>
                    <SelectSlice
                        hideMe={()=>setSelectedSliceBT(false)}
                        onSubmit={(slices)=>{
                            setRedeemingSlices(slices);
                            setSelectedSliceBT(false);
                            setConfirmFreePizza(true);
                        }}
                    />
                </BottomSheet>
            }
            {                
                confirmFreePizza && 
                <Modal align={'center'} onClose={() => { history.push('/slices') }} >
                    <ConfirmModal title={'You are going to get a free pizza, once the free pizza code is generated, you will lose the slice you selected. Do you want to continue?'}
                        confirmText={'Yes, Buy'}
                        onConfirm={freePizza}
                        onCancel={() => { 
                            setConfirmFreePizza(false);
                            setSelectedSliceBT(true);
                        }}
                    ></ConfirmModal>
                </Modal>
            }
            {
                showRedeem &&
                <Modal
                    align={'center'}
                    onClose={() => { history.push('/slices') }}
                    backgroundImage={Confetti}
                >
                    <RedeemPizza code={redeemCode} onClose={() => { 
                        setShowRedeem(false); 
                    }}></RedeemPizza>
                </Modal>
            }
        </>
    );
};

export default Slices;