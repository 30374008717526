import {httpGet, httpPatch, httpPost} from "@webservices/index";
import {Message} from "@webservices/Message";
import { API_URL, USER_API_URL } from "config";
import {ApiError} from "@types.d/ApiError";
import { ContractMissingDeployDataError } from "web3";

export interface User {
    userId:string
    fullName?: string|null
    email:string
    walletId?:string|null
    phoneNumber?: string|null
    dob?: string|null
    gender?: "male"|"female"|"none-binary"|null
    created_at: string
}

export interface UserUpdate {
    fullName?: string|null
    phoneNumber?: string|null
    dob?: string|null
    gender?: "male"|"female"|"none-binary"|null
    fcmToken?: string|null    
}

export interface UserStatistics {
    donates?: number|null
    freePizza?:number|null
    gift?:number|null    
}

export interface UserVouchers {
    voucherId: string
    userId: string
    creator?: string | null
    expiresOn: string
    code: string
    email?: string | null
    state: number
    created_at: string
    updated_at: string
    deleted_at?: null
}

export interface UserNotification{
    notificationId: string,
    readTimestamp: null,
    messageTitle: string,
    messageBody: string,
    created_at: string,
}

export const getUserInfoByUid = async (uId:string, onError?:(err:ApiError)=>void):Promise<User|undefined> => {
    const user =  await httpGet<Message<User>>(
        `${API_URL}/${USER_API_URL}users/byUserUid/${uId}`,
        undefined,
        onError
    )
    return user?.data
}

export const getUserInfo = async (onError?:(err:ApiError)=>void):Promise<User|undefined> => {
    const user =  await httpGet<Message<User>>(
        `${API_URL}/${USER_API_URL}users/${localStorage.getItem("sub")}`,
        undefined,
        onError
    )
    return user?.data
}

export const getUserStatisticsByUid = async (uId:string, onError?:(err:ApiError)=>void):Promise<UserStatistics|undefined> => {
    const userStatistics =  await httpGet<Message<UserStatistics>>(
        `${API_URL}/${USER_API_URL}users/statistics/${uId}`,
        undefined,
        onError
    )
    return userStatistics?.data
}

export const registerUser = async (credential:{
    userUId: string
    walletId?: string
    email: string
}, onError?:(err:ApiError)=>void):Promise<User|undefined> => {
    const user = await httpPost<any, Message<User>>(
        `${API_URL}/${USER_API_URL}users`,
        credential,
        undefined,
        onError
    )
    return user?.data;
}

export const updateUser = async (
    userInfo:UserUpdate, 
    onError?:(err:ApiError)=>void
):Promise<any> => {
    console.log("Proccessing: " + JSON.stringify(userInfo));
    const user = await httpPatch<any, Message<any>>(
        `${API_URL}/${USER_API_URL}users/${localStorage.getItem("sub")}`,
        userInfo,
        undefined,
        onError
    )
    console.log(userInfo);
    return user?.data;
}

export const updateWalletId = async (walletId:string, onError?:(err:ApiError)=>void):Promise<User|undefined> => {
    const user = await httpPatch<any, Message<User>>(
        `${API_URL}/${USER_API_URL}users/walletId/${localStorage.getItem("sub")}`,
        {walletId},
        undefined,
        onError
    )
    return user?.data;
}

export const getUserVouchersByUid = async (onError?:(err:ApiError)=>void):Promise<UserVouchers[]> => {
    const userVoucehrs =  await httpGet<Message<UserVouchers[]>>(
        `${API_URL}/${USER_API_URL}vouchers/byUserId/${localStorage.getItem("sub")}`,
        undefined,
        onError
    )
    return userVoucehrs?.data || [];
}

export const getUserVouchersByVoucherCode = async (voucherCode:string, onError?:(err:ApiError)=>void):Promise<UserVouchers|null> => {
    const userVoucehrs =  await httpGet<Message<UserVouchers|null>>(
        `${API_URL}/${USER_API_URL}vouchers/byVoucherCode/${voucherCode}`,
        undefined,
        onError
    )
    return userVoucehrs?.data || null;
}

export const getUserNotifications = async (onError?:(err:ApiError)=>void):Promise<UserNotification[]|null> => {
    const userNotifications =  await httpGet<Message<UserNotification[]|null>>(
        `${API_URL}/${USER_API_URL}notifications/byUserId/${localStorage.getItem("sub")}`,
        undefined,
        onError
    )
    return userNotifications?.data || null;
}

export const setNotificationRead = async (notificationId:string, onError?:(err:ApiError)=>void):Promise<any> => {
    const notification = await httpPatch<any, Message<any>>(
        `${API_URL}/${USER_API_URL}notifications/setRead/${notificationId}`,
        undefined,
        undefined,
        onError
    )
    return notification?.data;
}