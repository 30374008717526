import {getAuth} from "firebase/auth";
import {getFcmToken} from "./notification";
import {registerUser, updateUser} from "@webservices/user";
import {decodeJWT} from "@utilities/jwtDecode";
import moment from "moment";

interface Credential {
    uid:string
    token:string
    newUser:boolean
    refreshToken?:string
    phoneNumber?:string
    email?:string
    ref?:string|null
}

export const onUserLoggedIn = async (credential:Credential) => {
    const fcmToken =  await getFcmToken();
    if(credential.newUser) {
        const user = await registerUser({
            userUId: credential.uid,
            email: credential.email!
        });
        if(user){
            await setUser(credential, user.userId, fcmToken);
        }
    } else {
        const decode = decodeJWT(credential.token);
        await setUser(credential, decode.payload.userId, fcmToken);
    }
}

const setUser = async (credential:Credential, id:string, fcm?:string) => {
    localStorage.setItem("token", credential.token);
    localStorage.setItem("uid", credential.uid);
    localStorage.setItem("sub", id);
    await updateUser({
        fcmToken: fcm
    })
    window.location.replace(credential.ref || "/");
}

export const signOut = async () => {
    const auth = getAuth();
    localStorage.clear();
    localStorage.setItem("onBoard", "1")
    await auth.signOut();
    window.location.replace("/");
}