import TrackingCard from '@components/app/trackingCard';
import Icon from '@components/common/icon';
import { Loading } from '@components/common/svgIcons';
import { Order } from '@webservices/order';
import React, { FunctionComponent } from 'react';

interface InProgressProps {
    orders:Order[]
}

const InProgress:FunctionComponent<InProgressProps> = (props) => {
    return (
        <div className={"h-100"}>
            {
                props.orders.map((order, index)=>{
                    return <TrackingCard
                        name={"Order " + order.orderId.split('-')[0]}
                        lastUpdateTime={order.created_at}
                        status={order.orderStatus}
                    />
                })
            }
        </div> 
    );
};

export default InProgress;