import {httpGet, httpPost} from "@webservices/index";
import {Message} from "@webservices/Message";
import {API_URL, USER_API_URL} from "../../../config";
import { ApiError } from "@types.d/ApiError";
import {Slice} from "@types.d/Slice";

export interface UserSlice {
    userSliceId: string
    userId: string
    sliceId: number
    amount: number
    status: number
    walletId: string | null
    lastTransactionId: string
    hold: number
    created_at: string
    updated_at: string | null
    deleted_at: string | null
    sliceTypeId: number
}

interface ReleaseResult{
    voucherId:string
    userId:null
    creator:string
    expiresOn:string
    code:string
    email:string
    state:number
    created_at:string
    updated_at:string|null
    deleted_at:string|null
}

//ToDO: just remove & replace everywhere we fucked David after Fucking David
export const getUserSlices = async ():Promise<UserSlice[]> => {
    const userSlices = await httpGet<Message<UserSlice[]>>(
        `${API_URL}/${USER_API_URL}userSlices/byUserId/${localStorage.getItem("sub")}`,
    );    
    return userSlices?.data || [];
}

//ToDO: just remove toFuckingDavidSlices after Fucking David
const toFuckingDavidSlices = async (slices:Slice[]):Promise<Slice[]> => {
    let fuckingDavidSlices:Slice[] = [];

    
    const userSlices = await httpGet<Message<UserSlice[]>>(
        `${API_URL}/${USER_API_URL}userSlices/byUserId/${localStorage.getItem("sub")}`,
    );
    
    console.log("Here: " + userSlices?.data);

    let temp = [...userSlices?.data || []];

    slices.map(selectedSlice => {
        for(let counter:number = 0; counter < selectedSlice.amount; counter++){
            const index = temp.findIndex(item => item.sliceTypeId === selectedSlice.id);
            const sliceId = temp[index].sliceId;

            temp = temp.filter(item => item.sliceId !== sliceId);

            fuckingDavidSlices.push({
                id: sliceId,
                amount: 1
            })
        }
    });
    console.log(JSON.stringify(fuckingDavidSlices));
    return fuckingDavidSlices;
}

export const giftSlices = async (slices:Slice[], targetEmail:string, onError?:(err:ApiError)=>void):Promise<ReleaseResult|undefined> => {    
    const fuckingDavidResult = await toFuckingDavidSlices(slices);
    const releaseResult = await httpPost<any, Message<ReleaseResult>>(
        `${API_URL}/${USER_API_URL}userSlices/gift/${localStorage.getItem("sub")}`,
        {
            slices: fuckingDavidResult,
            email:targetEmail
        },
        undefined,
        onError
    )
    return releaseResult?.data;
}

export const donateSlices = async (slices:Slice[], onError?:(err:ApiError)=>void):Promise<ReleaseResult|undefined> => {    
    const fuckingDavidResult = await toFuckingDavidSlices(slices);
    const releaseResult = await httpPost<any, Message<ReleaseResult>>(
        `${API_URL}/${USER_API_URL}userSlices/donate/${localStorage.getItem("sub")}`,
        {slices: fuckingDavidResult},
        undefined,
        onError
    );
    return releaseResult?.data;
}

export const getFreePizza = async (slices:Slice[], onError?:(err:ApiError)=>void):Promise<ReleaseResult|undefined> => {
    const fuckingDavidResult = await toFuckingDavidSlices(slices);
    const releaseResult = await httpPost<any, Message<ReleaseResult>>(
        `${API_URL}/${USER_API_URL}userSlices/freePizza/${localStorage.getItem("sub")}`,
        {slices: fuckingDavidResult},
        undefined,
        onError
    )    
    return releaseResult?.data;
}