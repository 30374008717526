import React, {useEffect, useState} from 'react';
import Header from "@components/layouts/layoutElements/header";
import CustomButton from "@components/common/customButton";
import Text from "@components/common/text";
import {getUnlockedPatterns, Pattern} from "@webservices/patterns";
import UnlockedPatternCard from "@components/app/unlockedPatternCard";
import Icon from "@components/common/icon";
import {Heart} from "@components/common/svgIcons";

const Investments = () => {

    const [patterns, setPatterns] = useState<Pattern[]>([]);
    const [loadingPatterns, setLoadingPatterns] = useState<boolean>(true);

    useEffect(()=>{
        (async ()=>{
            const fetchPattern = await getUnlockedPatterns();
            setPatterns(fetchPattern);
        })()
    }, [])

    return (
        <>
            <Header
                title={"my investments"}
                backButton
            />
            <div className={"d-flex flex-column align-items-center px-4"}>
                <div className={"d-flex flex-column w-100 align-items-center cms-back-glass p-4 rounded-4"}>
                    <p className={"bold f-s-7 primary-gradient-text mb-1"}>€150</p>
                    <p className={"f-s-8 primary-gradient-text mb-4"}><Text value={"revenue"} class={"capitalize"}/></p>
                    <CustomButton
                        theme={"violet-gradient"}
                        text={<Text value={"cash out"} class={"capitalize"}/>}
                        onClick={()=>{}}
                    />
                </div>
            </div>
            <div className={"justify-content-between flex-wrap px-4 d-flex mt-3"}>
                {
                    patterns.map((pattern, index)=>{
                        return <div
                            key={index}
                            className={"d-flex flex-column align-items-center cms-back-glass rounded-4 mb-3 p-3 w-50 mb-2"}
                            style={{flexBasis: "calc(50% - 0.5rem)"}}
                        >
                            <UnlockedPatternCard pattern={pattern}/>
                            <p className={"d-flex gap-2 my-3"}><Text value={"revenue"} class={"capitalize"}/><span className={"bold"}>€45</span></p>
                            <CustomButton
                                text={<Text value={"sell"} class={"capitalize"}/>}
                                theme={"primary-gradient"}
                                icon={<Icon size={18} color={"none"} icon={<Heart/>}/>}
                            />
                        </div>
                    })
                }
            </div>
        </>
    );
};

export default Investments;