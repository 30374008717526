import React, {useEffect, useState} from 'react';
import {Favorite, getFavorites} from "@webservices/favorites";
import MenuCard from "@components/app/menuCard";
import Icon from "@components/common/icon";
import {Loading} from "@components/common/svgIcons";

const Favorites = () => {

    const [favorites, setFavorites] = useState<Favorite[]>([]);
    const [loadingFavorites, setLoadingFavorites] = useState<boolean>(true);

    useEffect(()=>{
        (async ()=>{
            const fetchFavorites = await getFavorites();
            setFavorites(fetchFavorites);
            setLoadingFavorites(false);
        })()
    }, [favorites])

    return (
        <div className={"d-flex justify-content-between flex-wrap w-100 p-3 overflow-auto h-100"}>
            {
                !loadingFavorites ? favorites.map((favorite, index) => {
                    return <div
                        className={"w-50 mb-2"}
                        style={{ flexBasis: "calc(50% - 0.5rem)" }}
                        key={index}
                    >
                        <MenuCard
                            menuId={""}
                            pizzaId={favorite.pizzaId}
                            image={favorite.image}
                            name={favorite.name}
                            details={favorite.details}
                            calories={favorite.calories}
                            weight={favorite.weight}
                            price={favorite.price}
                        />
                    </div>
                }) :
                    <div className={"w-100 d-flex h-100 justify-content-center align-items-center"}>
                        <Icon size={30} color={"none"} icon={<Loading />} gradient />
                    </div>
            }
        </div>
    );
};

export default Favorites;