import React, {FunctionComponent, useContext, useEffect, useMemo, useState} from 'react';
import styles from "./navigationLayout.module.scss"
import Icon from "@components/common/icon";
import {
    Cart,
    Favorites,
    PersonalInfo,
    HomeSolid, MoodDetection,
    Peer,
    PizzaGame
} from "@components/common/svgIcons";
import Text from "@components/common/text";
import {useHistory} from "react-router-dom";
import navbarBack from "@assets/icons/navbar.svg"
import navGameBtn from "@assets/icons/game-nav.svg"
import navGameActiveBtn from "@assets/icons/game-nav-active.svg"
import {GlobalContext} from "../../../contexts/GlobalContext";
import Modal from "@components/common/modal";
import MoodDetectionModal from "@components/modals/moodDetecion";

interface NavigationLayoutProps {
    children?: React.ReactNode
}

const NavigationLayout:FunctionComponent<NavigationLayoutProps> = (props) => {

    const history = useHistory();

    const navbar = [
        {
            label: <Text value={"home"} class={"capitalize"}/>,
            mainIcon: <HomeSolid/>,
            path:"/"
        },
        {
            label: <Text value={"cart"} class={"capitalize"}/>,
            mainIcon: <Cart/>,
            path:"/cart"
        },
        {
            label: <Text value={"game"} class={"capitalize"}/>,
            mainIcon: <Peer/>,
            path:"/slices"
        },
        // {
        //     label: <Text value={"favorites"} class={"capitalize"}/>,
        //     mainIcon: <Favorites/>,
        //     path:"/favorites"
        // },
        {
            label: <Text value={"mood"} class={"capitalize"}/>,
            mainIcon: <MoodDetection/>,
            path:"/mood-detection",
            clickOverride:()=>{setShowMoodDetectionModal(true)}
        },
        {
            label: <Text value={"profile"} class={"capitalize"}/>,
            mainIcon: <PersonalInfo/>,
            path:"/profile"
        }
    ]

    const {cart} = useContext(GlobalContext);

    const [path, setPath] = useState<string>();
    const [showMoodDetectionModal, setShowMoodDetectionModal] = useState<boolean>(false);


    useEffect(()=>{
        history.listen(location => setPath(location.pathname))
    }, [])

    const Navbar = useMemo(()=>{
        return navbar.map((nav, index)=>{
            if(index === 2){
                return <div className={styles.button} key={index}/>
            }
            return <div
                className={`${styles.button}`}
                onClick={()=>{nav.clickOverride ? nav.clickOverride() : history.push(nav.path)}}
                key={index}
            >
                {
                    history.location.pathname === nav.path ?
                        <Icon size={20} color={"none"} icon={nav.mainIcon} gradient/> :
                        <Icon size={20} color={"dark-gray"} icon={nav.mainIcon} />
                }
                <p className={`${history.location.pathname === nav.path ? "primary-gradient-text bold f-s-4" : "f-s-2"}  mt-2`}>
                    {nav.label}
                </p>
                {
                    (index === 1 && cart.length > 0) && <span className={styles.cartAlert} >
                        <span className={"bold cms-color-black f-s-1"}>{cart.length}</span>
                    </span>
                }
            </div>
        })
    }, [path, cart])

    return (
        <div className={styles.container}>
            <div className={styles.contentBack}>
                <div className={styles.content}>
                    {props.children}
                </div>
            </div>
            <div className={styles.nav}>
                <div className={styles.navbarBackLeft}/>
                <img
                    src={navbarBack}
                    className={styles.navBackground}
                    alt={"navbar background"}
                />
                <div className={styles.navbarBackRight}/>
                <div
                    className={styles.navGameBtn}
                    onClick={()=>history.push("slices")}
                >
                    {
                        history.location.pathname === "/slices" ?
                            <Icon size={30} color={"white"} icon={<PizzaGame/>}/> :
                            <Icon size={30} color={"none"} icon={<PizzaGame/>} gradient/>
                    }
                    <img
                        src={history.location.pathname === "/slices" ? navGameActiveBtn : navGameBtn}
                        alt={"navbar game btn background"}
                    />
                </div>

                {Navbar}
            </div>
            {
                showMoodDetectionModal && <Modal
                    align={"center"}
                    onClose={()=>setShowMoodDetectionModal(false)}
                >
                    <MoodDetectionModal
                        onClose={()=>setShowMoodDetectionModal(false)}
                    />
                </Modal>
            }
        </div>
    );
};

export default NavigationLayout;