import React, { useEffect, useState } from 'react';
import Icon from "@components/common/icon";
import Input from "@components/common/form/input";
import Radio from "@components/common/form/radio";
import Text from "@components/common/text";
import styles from "./personalInfo.module.scss";
import BottomSheet from "@components/common/bottomSheet";
import { useHistory } from "react-router-dom";
import {PersonalInfo as Person, Phone, Envelop, Calender, Down, Back, Loading} from "@components/common/svgIcons";
import CustomButton from '@components/common/customButton';
import { getUserInfo, updateUser, User, UserUpdate } from "@webservices/user";
import SetTime from "@components/bottomSheets/setTime";
import moment from "moment";
import { failToast } from '@toast/index';

const PersonalInfo = () => {

    const [showSaveButton, setShowSaveButton] = useState<boolean>(false);
    const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
    const [saveLoading, setSaveLoading] = useState<boolean>(false);

    const [userInfo, setUserInfo] = useState<User|undefined>();
    const [fullName, setFullName] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [gender, setGender] = useState<"male"|"female"|"none-binary"|null>();
    const [birthDate, setBirthDate] = useState<string|undefined>(undefined);

    const history = useHistory();

    useEffect(() => {
        (async () => {
            const userInfo = await getUserInfo();
            setUserInfo(userInfo);
            setBirthDate(userInfo?.dob ? moment(userInfo?.dob).format("DD/MM/YYYY") : "");
            setGender(userInfo?.gender || null);
            setFullName(userInfo?.fullName || "");
            setPhoneNumber(userInfo?.phoneNumber || "");
        })()
    }, [])

    useEffect(()=>{
        if(
            ((fullName !== userInfo?.fullName) ||
            (phoneNumber !== userInfo?.phoneNumber) ||
            (gender !== userInfo?.gender) ||
            (birthDate !== moment(userInfo?.dob).format("DD/MM/YYYY")))
            &&
            (
                fullName !== "" && 
                phoneNumber !== "" && 
                birthDate !== undefined
            )
        ){
            console.log(birthDate);
            setShowSaveButton(true);
        } else {
            setShowSaveButton(false);
        }
    }, [fullName, gender, phoneNumber, birthDate])

    const saveChanges = async () => {
        let updates: UserUpdate|null = {
            fullName,
            phoneNumber,
            dob:birthDate!,
            gender
        };
        setSaveLoading(true);
        const updateResult = await updateUser(updates, ()=>{setSaveLoading(false)});

        if(updateResult !== undefined)
            history.push('/profile');
        else
            failToast("Error saving changes!");

        setSaveLoading(false);           
    }

    return (
        <>
            <div className={"d-flex justify-content-around flex-wrap w-100 p-3 overflow-auto"} style={{ alignItems: 'center' }}>
                <div className={styles.personalInfoHeader}>
                    <div onClick={() => {
                        history.push('/profile');
                    }}>
                        <Icon size={24} color={"none"} icon={<Back />} gradient />
                    </div>
                    <h1 className={"bold primary-gradient-text f-s-6 text-center my-3"}>
                        <Text value={"Personal Information"} class={"capitalize"} />
                    </h1>
                    <div></div>
                </div>
                <div className={"w-100 mt-4"}>
                    <Input
                        label={"Full Name"}
                        labelStyle={{ paddingLeft: '17px' }}
                        placeHolder={"Enter your full name"}
                        default={fullName}
                        onChange={(id, value) => {
                            setFullName(value||"");
                        }}
                        preIcon={<Icon size={32} color={"none"} icon={<Person />} gradient />}
                    />
                </div>
                <div className={"w-100 mt-4"}>
                    <Input
                        label={"Phone Number"}
                        labelStyle={{ paddingLeft: '17px' }}
                        placeHolder={"Enter your phone number"}
                        default={phoneNumber}
                        onChange={(id, value) => {
                            setPhoneNumber(value||"");
                        }}
                        preIcon={<Icon size={32} color={"none"} icon={<Phone />} gradient />}
                    />
                </div>
                <div className={"w-100 mt-4"}>
                    <Input
                        label={"Email"}
                        lock
                        labelStyle={{ paddingLeft: '17px' }}
                        placeHolder={"Enter your email"}
                        default={userInfo?.email}
                        onChange={() => {}}
                        preIcon={<Icon size={25} color={"none"} icon={<Envelop />} gradient />}
                    />
                </div>
                <div className={"w-100 mt-4"}>
                    <Input
                        onClick={()=>setShowDatePicker(true)}
                        label={"Birth Date"}
                        labelStyle={{ paddingLeft: '17px' }}
                        placeHolder={"01/01/2000"}
                        default={birthDate}
                        onChange={() => {}}
                        preIcon={<Icon size={25} color={"none"} icon={<Calender />} gradient />}
                        icon={{
                            icon: <Icon size={12} color={"none"} icon={<Down />} gradient />,
                            action: () => { setShowDatePicker(true) }
                        }}
                        lock
                    />
                </div>
                <div className={"w-95 mt-4"}>
                    <h1>Gender</h1>
                    <Radio
                        id={"preference"}
                        onChange={(id, value) => {
                            setGender(value);
                        }}
                        default={gender}
                        options={[
                            { title: "Female", value: "female" },
                            { title: "Male", value: "male" },
                            { title: "Rather not to say", value: "ratherNot" },
                        ]}
                    />
                </div>
                {
                    showDatePicker &&
                    <BottomSheet onClose={() => setShowDatePicker(false)}>
                        <SetTime
                            id={"deliveryTime"}
                            format={"DD/MM/YYYY"}
                            default={birthDate}
                            onCancel={()=>{
                                setShowDatePicker(false);}}
                            onSave={(id, value)=>{
                                console.log(value);
                                setBirthDate(value);
                                setShowDatePicker(false);
                            }}
                            pickerType={"date"}
                        />
                    </BottomSheet>
                }
                {

                    <div className='mt-5'>
                        <CustomButton
                            className={styles.buttonSave}
                            theme={"primary-gradient"}
                            disabled={!showSaveButton}
                            loading={saveLoading}
                            loadingIcon={<Icon size={20} color={"none"} icon={<Loading/>}/>}
                            text={<Text value={"Save"} class={"capitalize"} />}
                            onClick={() => {
                                saveChanges().catch();
                            }}
                        />
                    </div>
                }
            </div>
        </>
    );
};


export default PersonalInfo;