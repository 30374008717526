import React from 'react';
import styles from "./onboarding.module.scss";
import CustomButton from "@components/common/customButton";
import Text from "@components/common/text";
import Onboarding01 from '../../assets/images/Onboarding01.png';
import Onboarding02 from '../../assets/images/Onboarding02.png';
import Onboarding03 from '../../assets/images/Onboarding03.png';

import { useHistory } from "react-router-dom";
import HorizontalSlideShow from "@components/basic/horizontalSlideShow";

const Onboarding = () => {

    const slides = [
        {
            title: "Face Scan to order Pizza",
            text: "Experience a whole new way to order pizza with our face scan technology.",
            image: Onboarding01
        },
        {
            title: "Game On: Play and win prizes",
            text: "Dive into our NFT-powered games, have fun, and earn rewards that could satisfy your craving for a tasty treat.",
            image: Onboarding02
        },
        {
            title: "Quick-Order",
            text: "From hunger to happiness in a few taps. Order your pizza now!",
            image: Onboarding03
        }
    ]

    const history = useHistory();

    const createSlide = (slide: any, index: number) => {
        return (
            <div className={"d-flex flex-column justify-content-around align-items-center flex-grow-1"} style={{height: '100%'}}>
                <div>
                    <img src={slide.image} alt="" />
                </div>
                <div className={"d-flex flex-column align-items-center"}>
                    <h2 className={"mb-3 text-capitalize  primary-gradient-text bold f-s-6"}>
                        <Text value={slide.title} class={"capitalize"} />
                    </h2>
                    <p className={"bold f-s-3 text-center w-75"}><Text value={slide.text} /></p>
                </div>
                <div className={"d-flex justify-content-center align-items-center position-relative"} style={{width: '80%',}}>
                    <div className={`${styles.bullets} ${styles.centeredBullets}`}>
                        {
                            slides.map((slide, i) => {
                                return <span className={i === index ? styles.activeBullet : ""} key={i} />
                            })
                        }
                    </div>
                    <div className={styles.footerButtons}>
                        {index === slides.length - 1 ?
                            <CustomButton                                
                                theme={"primary-gradient"}
                                text={<Text value={"Get started"} class={"capitalize"} />}
                                onClick={() => {
                                    history.push('/');
                                    localStorage.setItem("onBoard", "1");
                                }}
                            />
                            : 
                            <p style={{ textDecoration: "underline" }}
                                onClick={() => {
                                    slide.index = 2;
                                    history.push('/');
                                    localStorage.setItem("onBoard", "1");
                                }}>
                                <Text value={"skip"} class={"capitalize"} />
                            </p>
                        }
                    </div>
                </div>
            </div>
        )
    }

    return (
        <HorizontalSlideShow
            slides={slides.map((slide, index) => (createSlide(slide, index)))}
        />
    );
};

export default Onboarding;