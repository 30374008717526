import React, {FunctionComponent, useEffect, useRef} from 'react';
import styles from './Icon.module.scss';
import cmsColors from "@types.d/CmsColors";

export interface IconProps {
    size:number
    color:cmsColors | "none"
    icon:React.ReactElement
    className?: string
    gradient?:"primary"|boolean
}

const Icon:FunctionComponent<IconProps> = (props) => {

    const iconRef = useRef<HTMLDivElement>(null);

    const getPaths = (paths:HTMLCollectionOf<SVGPathElement>):string => {
        let p:string="";
        for (let i = 0; i <paths.length; i++){
            paths[i].setAttribute("fill", "url(#myGradient)");
            p += paths[i].outerHTML;
        }
        return p;
    }

    useEffect(()=>{
        if(!props.gradient) return;
        const svgTags = iconRef.current!.getElementsByTagName("svg");
        const paths = iconRef.current!.getElementsByTagName("path");
        svgTags[0].innerHTML = `
            <defs>
                <linearGradient id="myGradient" x1="-4.76837e-07" y1="32" x2="36.1934" y2="26.209" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#E13E3B"></stop>
                    <stop offset="1" stop-color="#E87E09"></stop>
                </linearGradient>
            </defs>
            ${getPaths(paths)}
        `;
    }, [props.gradient])
        
    return (        
        <div
            className={`d-flex justify-content-center align-items-center ${props.color !== "none" ? `cms-fill-${props.color}` : ""} ${styles.container} ${props.className}`}
            style={{width:props.size, height:props.size}}
            ref={iconRef}
        >
            {props.icon}
        </div>
    );
};

export default Icon;