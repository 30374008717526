import TrackingCard from '@components/app/trackingCard';
import { Order } from '@webservices/order';
import React, { FunctionComponent } from 'react';

interface ReadyProps {
    orders:Order[]
}

const Ready:FunctionComponent<ReadyProps> = (props) => {
    return (
        <div className={"h-100"}>
        {
            props.orders.map((order, index)=>{
                return <TrackingCard
                    name={"Order " + order.orderId.split('-')[0]}
                    lastUpdateTime={order.created_at}
                    status={order.orderStatus}
                />
            })
        }
    </div>
    );
};

export default Ready;