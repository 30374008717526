if (process.env.REACT_APP_ENV === "local") {
    module.exports.API_URL = "https://us-central1-pzza-ebae9.cloudfunctions.net";
    module.exports.USER_API_URL = "userApi/1.0/";
    module.exports.FACE_API_URL = "faceApi/1.0/";
    module.exports.PIZZA_API_URL = "pizzaApi/1.0/";
    module.exports.HOME_URL = "http://localhost:4500/";
    module.exports.STATICS_URL = "";
    module.exports.DEF_BRANCH = "087d6567-af01-4ff2-b0d2-7a203fee3c0e";
    module.exports.OPENSEA_BASE_URL = "https://testnets.opensea.io/";
    module.exports.OPENSEA_COLLECTION_URL = "https://testnets.opensea.io/collection/pzza-t";
    module.exports.CONTRACT_ADDRESS = "0x0B3Aa9eab5757072110716A5ee0274bdDC53D37b";
    module.exports.OPERATOR_ADDRESS = "0x0074fCd96a28bb946b096E48aAB12Db0DC8003E9";
    module.exports.AIQO_WEBSITE_URL = "https://aiqo.ai/";
    module.exports.NETWORK_ID = 80001;
}
if(process.env.REACT_APP_ENV === "dev") {
    module.exports.API_URL = "https://us-central1-pzza-ebae9.cloudfunctions.net";
    module.exports.USER_API_URL = "userApi/1.0/";
    module.exports.FACE_API_URL = "faceApi/1.0/";
    module.exports.PIZZA_API_URL = "pizzaApi/1.0/";
    module.exports.HOME_URL = "https://pzza-ebae9.web.app/";
    module.exports.STATICS_URL = "";
    module.exports.DEF_BRANCH = "087d6567-af01-4ff2-b0d2-7a203fee3c0e";
    module.exports.OPENSEA_BASE_URL = "https://testnets.opensea.io/";
    module.exports.OPENSEA_COLLECTION_URL = "https://testnets.opensea.io/collection/pzza-t";
    module.exports.CONTRACT_ADDRESS = "0x0B3Aa9eab5757072110716A5ee0274bdDC53D37b";
    module.exports.OPERATOR_ADDRESS = "0x0074fCd96a28bb946b096E48aAB12Db0DC8003E9";
    module.exports.AIQO_WEBSITE_URL = "https://aiqo.ai/";
    module.exports.NETWORK_ID = 80001;
}
if(process.env.REACT_APP_ENV === "stage") {
    module.exports.API_URL = "https://us-central1-stage-justu.cloudfunctions.net/api/1.0/";
    module.exports.HOME_URL = "";
    module.exports.STATICS_URL = "";
    module.exports.OPENSEA_BASE_URL = "https://testnets.opensea.io/";
    module.exports.OPENSEA_COLLECTION_URL = "https://testnets.opensea.io/collection/pzza-t";
    module.exports.CONTRACT_ADDRESS = "0x508892d6691b379f23662c251ebf7360eeb648ee";
    module.exports.OPERATOR_ADDRESS = "0x0074fCd96a28bb946b096E48aAB12Db0DC8003E9";
    module.exports.AIQO_WEBSITE_URL = "https://aiqo.ai/";
}
if(process.env.REACT_APP_ENV === "main") {
    module.exports.API_URL = "https://us-central1-pzza-ebae9.cloudfunctions.net";
    module.exports.USER_API_URL = "UserApi/1.0/";
    module.exports.FACE_API_URL = "FaceApi/1.0/";
    module.exports.PIZZA_API_URL = "PizzaApi/1.0/";
    module.exports.HOME_URL = "https://app.pzza.io/";
    module.exports.STATICS_URL = "";
    module.exports.DEF_BRANCH = "00024554-a7b9-4ab9-8db7-df68b927748f";
    module.exports.OPENSEA_BASE_URL = "https://opensea.io/";
    module.exports.OPENSEA_COLLECTION_URL = "https://opensea.io/collection/pzzacollection";
    module.exports.CONTRACT_ADDRESS = "0x508892D6691B379F23662C251eBf7360eeB648EE";
    module.exports.OPERATOR_ADDRESS = "0x82484Ecce0ac1a5B3484F725d320ffF8FCcd872c";
    module.exports.AIQO_WEBSITE_URL = "https://aiqo.ai/";
    module.exports.NETWORK_ID = 137;
}
