import {initializeApp} from "firebase/app";
import {getMessaging} from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyDZqQStjuyRO3W_Aruv_3-FrgxWI5n_Icg",
    authDomain: "pzza-ebae9.firebaseapp.com",
    databaseURL: "https://pzza-ebae9-default-rtdb.firebaseio.com",
    projectId: "pzza-ebae9",
    storageBucket: "pzza-ebae9.appspot.com",
    messagingSenderId: "1083206466855",
    appId: "1:1083206466855:web:e72fca3f95b7a7807c7d02",
    measurementId: "G-JVRJ8V92EG"
};



export const initiateFirebase = () => {
    initializeApp(firebaseConfig);
}

// TODO uncomment to active notification
export const messaging = getMessaging(initializeApp(firebaseConfig));
