import {httpGet, httpPost} from "@webservices/index";
import {Message} from "@webservices/Message";
import { API_URL, PIZZA_API_URL } from "config";
import {ApiError} from "@types.d/ApiError";

export interface Pattern {
    patternId: string
    branchId: string
    ownerWalletId: string | null
    lastTransactionId: null
    properties: {[k:string]:any}
    patternSlices: {
        patternSliceId: string
        sliceId: number
        amount: number
    }[]
}

export interface LockedPattern extends Pattern{
    customerRate:number
    revenueRate:number
    peopleBeforeYou:number
}

export const getLockedPatterns = async ():Promise<LockedPattern[]> => {
    const patterns = await httpGet<Message<LockedPattern[]>>(
        `${API_URL}/${PIZZA_API_URL}patterns/unlocked/0`
    )
    return patterns?.data || [];
}

export const getUnlockedPatterns = async ():Promise<LockedPattern[]> => {
    // return patterns.map(p=>({...p.pattern, ...p.statics}));
    return []
}

export const claimPattern = async (patternId:string, onError?:(err:ApiError)=>void) => {
    await httpPost<any, any>(
        `${API_URL}/${PIZZA_API_URL}patterns/claim/${localStorage.getItem("sub")}`,
        {patternId},
        undefined,
        onError
    )
}