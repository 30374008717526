import React, {useContext, useEffect, useState} from 'react';
import Header from "@components/layouts/layoutElements/header";
import IconHeader from "@components/common/iconHeader";
import {
    Calender,
    Card,
    Clock,
    Edit,
    Google,
    Left, Loading,
    LocationPin,
    Phone
} from "@components/common/svgIcons";
import Divider from "@components/common/divider";
import Radio from "@components/common/form/radio";
import Icon from "@components/common/icon";
import CustomButton from "@components/common/customButton";
import Text from "@components/common/text";
import {GlobalContext} from "../../../contexts/GlobalContext";
import {useHistory} from "react-router-dom";
import BottomSheet from "@components/common/bottomSheet";
import SetTime from "@components/bottomSheets/setTime";
import moment from "moment";
import {validate} from "@utilities/validator";
import {creatOrderSchema} from "@schemas/createOrderSchema";
import {failToast} from "@toast/index";
import {createOrder} from "@webservices/order";
import Input from "@components/common/form/input";
import {ApiError} from "@types.d/ApiError";
import {createPayment} from "@webservices/payment";
import { getUserVouchersByVoucherCode } from '@webservices/user';

const Checkout = () => {

    const history = useHistory();

    const { cart, totalPrice, applyDiscount, flushCart } = useContext(GlobalContext);

    const [deliveryType, setDeliveryType] = useState<"now"|"schedule">("now");
    const [deliveryTime, setDeliveryTime] = useState<string>();
    const [preference, setPreference] = useState<"preBaked"|"cooked">("preBaked");
    const [showSetTimeBt, setShowSetTimeBt] = useState<boolean>(false);
    const [orderLoading, setOrderLoading] = useState<boolean>(false)
    const [voucherCode, setVoucherCode] = useState<string|undefined>(undefined);
    const [calculatedDiscount, setCalculatedDiscount] = useState<number>(0);

    useEffect(()=>{
        if(cart.length < 1) history.push("/cart");
    }, [])

    useEffect(()=>{
        if(deliveryType === "now") return;
    }, [deliveryType])

    const placePreOrder = async () => {
        // const orderObject = {
        //     orderItems: cart.map(item=>({
        //         menuId: item.id,
        //         amount: item.count,
        //     })),
        //     cooked:preference === "cooked"
        // }
        // if(!validate(orderObject, creatOrderSchema)){
        //     failToast(<Text value={"invalid order data"} class={"capitalize"}/>)
        //     return;
        // }
        setOrderLoading(true);        
        const voucher = await getUserVouchersByVoucherCode(voucherCode!, onOrderError);        
        if(voucher?.state === 1)
            applyDiscount(totalPrice - 12.5);
        else
            failToast("Invalid/Inactive Voucher Code!");
        
        //TODO Ad Google Pay process here

        setOrderLoading(false);
    }

    const placeOrder = async () => {
        const orderObject = {
            orderItems: cart.map(item=>({
                menuId: item.id,
                amount: item.count,
            })),
            //voucherCode: "",
            cooked:preference === "cooked"
        }
        if(!validate(orderObject, creatOrderSchema)){
            failToast(<Text value={"invalid order data"} class={"capitalize"}/>)
            return;
        }
        setOrderLoading(true);
        const order = await createOrder(orderObject, voucherCode, onOrderError);

        //TODO Ad Google Pay process here
        if(order){
            flushCart();
            const payment = await createPayment(order, onOrderError);
            if(payment) history.push("/track-orders")
        }
        setOrderLoading(false);
    }

    const onOrderError = (err:ApiError) =>{
        setOrderLoading(false)
        failToast(<Text value={"a problem happened in creating an order"} class={"capitalize"}/>)
    }

    const onPaymentError = (err:ApiError) =>{
        setOrderLoading(false)
        failToast(<Text value={"a problem happened in payment process"} class={"capitalize"}/>)
    }

    return (
        <>
            <Header
                title={"checkout"}
                backButton
            />
            <div className={"d-flex flex-column flex-grow-1 overflow-auto w-100 px-4"}>
                <IconHeader icon={<Clock/>} text={"delivery time"}/>
                <Radio
                    id={"delivery"}
                    onChange={(id, value)=>{setDeliveryType(value)}}
                    options={[
                        {title:"now", value:"now"},
                        {title:"schedule for later", value:"schedule"},
                    ]}
                    default={deliveryType}
                />
                {
                    deliveryType === "schedule" && <div className={"d-flex justify-content-between align-items-center mt-3"}>
                        <div className={"d-flex gap-2 align-items-center"}>
                            <Icon size={20} color={"black"} icon={<Calender/>}/>
                            <p>{`At ${moment(deliveryTime).format("hh:mm A")} Today`}</p>
                        </div>
                        <div
                            className={"d-flex gap-2 align-items-center"}
                            onClick={()=>setShowSetTimeBt(true)}
                        >
                            <p>edit</p>
                            <Icon size={20} color={"black"} icon={<Edit/>}/>
                        </div>
                    </div>
                }
                <Divider/>
                <h2>Choose your preference</h2>
                <Radio
                    id={"preference"}
                    onChange={(id,value)=>{setPreference(value)}}
                    options={[
                        {title:"Takeaway", value:"preBaked"},
                        {title:"Cooked", value:"cooked"},
                    ]}
                    default={preference}
                />
                <Divider/>
                <h2>Enter Coupon code</h2>
                <div className={"w-100 d-flex gap-2 mt-3"}>
                    <Input
                        className={"flex-grow-1"}
                        preIcon={<Icon size={20} color={"black"} icon={<Edit/>}/> }
                        onChange={(id, value)=>{
                            console.log(value);
                            setVoucherCode(value);
                        }}
                    />
                    <CustomButton
                        style={{height:45}}
                        theme={"primary-gradient"}
                        onClick={() => {placePreOrder();}}
                        text={<Text value={"apply"} class={"capitalize"}/>}
                    />
                </div>
                <Divider/>
                <div className={"d-flex justify-content-between align-items-center opacity-50 mb-3"}>
                    <div className={"d-flex justify-content-between align-items-center gap-2"}>
                        <Icon size={25} color={"black"} icon={<LocationPin/>}/>
                        <p><Text value={"add delivery location"} class={"capitalize"}/></p>
                    </div>
                    <Icon size={25} color={"black"} icon={<Left/>}/>
                </div>
                <div className={"d-flex justify-content-between align-items-center opacity-50"}>
                    <div className={"d-flex justify-content-between align-items-center gap-2"}>
                        <Icon size={25} color={"black"} icon={<Phone/>}/>
                        <p><Text value={"add phone number"} class={"capitalize"}/></p>
                    </div>
                    <Icon size={25} color={"black"} icon={<Left/>}/>
                </div>
                <Divider/>
                <IconHeader icon={<Card/>} text={"payment"}/>
                <div className={"d-flex align-items-center gap-2"}>
                    <Icon size={18} color={"none"} icon={<Google/>}/>
                    <p><Text value={"pay"} class={"capitalize"}/></p>
                </div>
            </div>
            <div className={`d-flex justify-content-center w-100 p-3`}>
                <CustomButton
                    theme={"primary-gradient"}
                    text={
                        <span>
                            <Text value={"place order"} class={"capitalize"}/> | €{totalPrice.toFixed(1)}
                        </span>
                    }
                    style={{minWidth:180}}
                    onClick={placeOrder}
                    loading={orderLoading}
                    loadingIcon={<Icon size={20} color={"none"} icon={<Loading/>}/>}
                />
            </div>

            {/*time picker*/}
            {
                showSetTimeBt && <BottomSheet onClose={()=>{setShowSetTimeBt(false)}}>
                    <SetTime
                        id={"deliveryTime"}
                        onCancel={()=>{setShowSetTimeBt(false)}}
                        onSave={(id, value)=>{
                            setDeliveryTime(value);
                            setShowSetTimeBt(false);
                        }}
                        pickerType={"time"}
                    />
                </BottomSheet>
            }
        </>
    );
};

export default Checkout;