import React, {useEffect, useRef, useState} from 'react';
import styles from './moodDetector.module.scss';
import Header from "@components/layouts/layoutElements/header";
import CustomButton from "@components/common/customButton";
import Text from "@components/common/text";
import FaceMesh from "@assets/images/faceMesh.svg"
import {analyzeFace} from "@webservices/moodDetection";
import {Loading} from "@components/common/svgIcons";
import Icon from "@components/common/icon";
import MenuCard from "@components/app/menuCard";
import {useHistory} from "react-router-dom";
import {getMenuByStoreId, getMenuItem, Menu} from "@webservices/stores/menu";
import {failToast} from "@toast/index";
import * as faceapi from 'face-api.js';
import { error } from 'console';
import { DEF_BRANCH } from 'config';

const MoodDetector = () => {

    const history = useHistory();

    const videoRef = useRef<HTMLVideoElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const imageRef = useRef<HTMLImageElement>(null);

    const [userMood, setUserMood] = useState("Neutral");
    const [photoData, setPhotoData] = useState<string|null>(null);
    const [detectionLoading, setDetectionLoading] = useState<boolean>(false);
    const [pizza, setPizza] = useState<Menu|undefined>();
    const [menus, setMenus] = useState<Menu[]>([]);


    useEffect(()=>{
        (async () => {
            const MODEL_URL = "./models";
            await faceapi.nets.tinyFaceDetector.loadFromUri(MODEL_URL);
            await faceapi.nets.faceLandmark68Net.loadFromUri(MODEL_URL);
            await faceapi.nets.faceExpressionNet.loadFromUri(MODEL_URL);
            await faceapi.nets.ssdMobilenetv1.load(MODEL_URL);

            const menuItems = await getMenuByStoreId(DEF_BRANCH!);
            setMenus(menuItems);
        })()
        startCamera();
    }, [])

    const startCamera = async () => {
        try {
            if(videoRef.current){
                videoRef.current.srcObject = await navigator.mediaDevices.getUserMedia({ video: true });
            }
        } catch (err) {
            failToast(<Text value={"please grant access to use the camera"} class={"capitalize"}/>)
        }
    };

    const stopCamera = async () => {
        let srcObject = await navigator.mediaDevices.getUserMedia({ video: true });        
        let mediaTracks: any[] = srcObject.getVideoTracks();
        mediaTracks.forEach(track => {   
            console.log(`Stopping video device: ${track.label}`);
            track.stop();
            track.enabled = false;
            track = null;
        });
    };

    const capturePhoto = async () => {
        const video = videoRef.current!;
        const canvas = canvasRef.current!;
        const context = canvas.getContext('2d');

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        context!.drawImage(video, 0, 0, canvas.width, canvas.height);

        const photoDataUrl = canvas.toDataURL('image/png');
        setPhotoData(photoDataUrl);
        setDetectionLoading(true);

        loadModelsAndDetectExpression();        
    }

    const loadModelsAndDetectExpression = async () => {
        const faceExpressions:string[] = ['happy', 'sad', 'angry', 'fearful', 'surprised', 'disgusted', 'neutral'];
        try {
            const Detected = await faceapi
                .detectAllFaces(videoRef.current!, new faceapi.TinyFaceDetectorOptions())
                .withFaceLandmarks()
                .withFaceExpressions();

            const Result = [];
            const JSONObj: any = Detected[0].expressions;

            for (let item in JSONObj)
                Result.push({ 'mood': item, 'intensity': JSONObj[item] });

            Result.splice(7, 1);
            Result.sort((a, b) => a.intensity - b.intensity);
            Result.reverse();

            let Res = Result[0].mood.toLowerCase() === "neutral" ? Result[1] : Result[0];
            console.log(Res.mood);
            const expressionPos = faceExpressions.indexOf(Res.mood);
            console.log(expressionPos);
            expressionPos !== -1 ? setPizza(menus[expressionPos > 4 ? expressionPos % 5 : expressionPos ]) : setPizza(menus[0]);
            setUserMood(Res.mood);
        } catch (error) {
            setPizza(menus[0]);
            console.log(error);            
        }
        
        setDetectionLoading(false);
        stopCamera();
    }


    return (
        <div className={"d-flex flex-column h-100"}>
            <Header
                title={"take a photo"}
                backButton
            />
            <div className={"px-4 pb-4 flex-grow-1 overflow-auto"}>
                {
                    photoData === null ? <div className={styles.capture}>
                        <img className={styles.faceMesh} src={FaceMesh} alt={"face mesh"}/>
                        <video ref={videoRef} autoPlay />
                        <canvas ref={canvasRef} style={{ border: '1px solid green', display: 'none' }} />
                        <p className={"text-center my-3"}>
                            Please position your face within the designated area to capture your picture. We automatically take your picture
                        </p>
                        <CustomButton
                            theme={"primary-gradient"}
                            text={<Text value={"take a photo"} class={"capitalize"}/>}
                            onClick={capturePhoto}
                        />
                    </div> : <div className={styles.analyze}>
                        <img ref={imageRef} className={styles.capturedPhoto} src={photoData} alt={"captured photo"} />
                        {
                            detectionLoading ?  <div className={"d-flex w-100 justify-content-center mt-4"}>
                                <Icon size={30} color={"none"} icon={<Loading/>} gradient/>
                            </div> : <div className={"d-flex flex-column align-items-center"}>
                                <p className={"bold text-center my-3"}>You look {userMood}</p>
                                <p className={"text-center mb-3"}>Upon analyzing the emotions in your picture, we recommend you</p>                                
                                {
                                    pizza && <div className={"w-100"}>
                                        <MenuCard
                                            {...pizza}                                        />
                                    </div>
                                }
                                <div>
                                    <div className={"d-flex gap-2 mt-3"}>
                                        <CustomButton
                                            theme={"primary-outline"}
                                            text={<Text value={"browse menu"} class={"capitalize"}/>}
                                            onClick={()=>history.push("/")}
                                        />
                                        <CustomButton
                                            theme={"primary-gradient"}
                                            text={<Text value={"not now"} class={"capitalize"}/>}
                                            onClick={()=>history.push("/")}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
        </div>
    );
};

export default MoodDetector;