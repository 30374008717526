import React, { useEffect, useState } from 'react';
import MenuCard from "@components/app/menuCard";
import Icon from "@components/common/icon";
import styles from "./profile.module.scss";
import Header from "@components/layouts/layoutElements/header";
import { Gift, FreePizza, HeartOutline, GreenLeaves, PersonalInfo, Discount, TrackOrder, OrderHistory, Investment, DeleteAccount, Left as ChevronRight, Heart } from "@components/common/svgIcons";
import { useHistory } from "react-router-dom";
import { getUserStatisticsByUid, UserStatistics } from "@webservices/user";
import Modal from '@components/common/modal';
import ConfirmModal from '@components/modals/confirmModal';
import CustomButton from '@components/common/customButton';

const Profile = () => {

    const history = useHistory();
    const [showDeleteAccount, setShowDeleteAccount] = useState(false);

    const [statistics, setStatistics] = useState<UserStatistics|undefined>();

    useEffect(() => {
        (async () => {            
            let userID:string|null;
            userID = localStorage.getItem('sub');

            const userStatistics = await getUserStatisticsByUid(userID!);
            setStatistics(userStatistics);
        })()
    }, [])

    const deleteAccount = () => {

    }

    return (
        <>
            <Header
                title={"Profile"}
                menu
            />
            <div className={"d-flex justify-content-around flex-wrap w-100 p-3 overflow-auto h-100"} style={{alignItems: 'center'}}>
                <div className={styles.profileBoard}>
                    <div className={styles.itemContainer}>

                        <div className={styles.profileBoardItem}>
                            <div style={{ width: '38px' }}>
                                <Icon size={15} color={"black"} icon={<Gift />} />
                                <span>{statistics?.gift}</span>
                            </div>
                            <span>Gift</span>
                        </div>

                        <div className={styles.profileBoardItem}>
                            <div style={{ width: '38px' }}>
                                <Icon size={15} color={"black"} icon={<FreePizza />} />
                                <span>{statistics?.freePizza}</span>
                            </div>
                            <span>Free Pizza</span>
                        </div>

                    </div>

                    <div className={styles.itemContainer}>
                        <div className={styles.profileBoardItem}>
                            <div style={{ width: '38px' }}>
                                <Icon size={15} color={"black"} icon={<HeartOutline />} />
                                <span>{statistics?.donates}</span>
                            </div>
                            <span>Donate</span>
                        </div>
                        <div className={styles.profileBoardItem}>
                            <div style={{ width: '51px' }}>
                                <Icon size={15} color={"black"} icon={<GreenLeaves />} />
                                <span>0.0</span>
                            </div>
                            <span>Carbon Credit</span>
                        </div>
                    </div>
                </div>

                <div className={`${styles.profileItem} mt-4`}>
                    <div>
                        <div onClick={() => {
                            history.push('/personalInfo');}}>
                            <Icon size={18} color={"none"} icon={<PersonalInfo />} gradient />
                            <span>Personal Information</span>
                        </div>
                        <Icon size={24} color={"none"} icon={<ChevronRight />} gradient />
                    </div>
                    <div>
                        <div onClick={() => {
                            history.push('/favorites');}}>
                            <Icon size={18} color={"red"} icon={<HeartOutline />} gradient />
                            <span>Favorites</span>
                        </div>
                        <Icon size={24} color={"none"} icon={<ChevronRight />} gradient />
                    </div>
                    <div>
                        <div onClick={() => {
                            history.push('/track-orders');}}>
                            <Icon size={18} color={"green"} icon={<TrackOrder />} />
                            <span>Track Order</span>
                        </div>
                        <Icon size={24} color={"none"} icon={<ChevronRight />} gradient />
                    </div>
                    <div>
                        <div onClick={() => {
                            history.push('/track-orders');}}>
                            <Icon size={18} color={"light-gray"} icon={<OrderHistory />} />
                            <span>Order History</span>
                        </div>
                        <Icon size={24} color={"none"} icon={<ChevronRight />} gradient />
                    </div>
                    <div>
                        <div onClick={() => {
                            history.push('/investments');}}>
                            <Icon size={18} color={"blue"} icon={<Investment />} />
                            <span>My Investments </span>
                        </div>
                        <Icon size={24} color={"none"} icon={<ChevronRight />} gradient />
                    </div>
                    <div>
                        <div onClick={() => {
                            history.push('/discount');}}>
                            <Icon size={18} color={"none"} icon={<Discount />} />
                            <span>Discount</span>
                        </div>
                        <Icon size={24} color={"none"} icon={<ChevronRight />} gradient />
                    </div>
                    <div>
                        <div onClick={() => {
                                setShowDeleteAccount(true);
                            }}>
                            <Icon size={18} color={"red"} icon={<DeleteAccount />} gradient />
                            <span>Delete Account</span>
                        </div>
                    </div>
                </div>
                {
                    showDeleteAccount && 
                        <div id='modal' className={styles.deleteAccount} onClick={(e) => {
                            if (e.target === e.currentTarget){
                                setShowDeleteAccount(false);
                            }
                        }}>
                            <div className={styles.messageBox}>
                                <div className={styles.titleText}>Delete Account</div>
                                <div className={styles.bodyText}>By deleting your account you will lose everything like order history, but your assests will be available on your wallet.</div>
                                <div className={styles.bodyText}>Do you want to delete your account?</div>
                                <div className={styles.buttonsArea}>
                                    <CustomButton
                                        style={{ color: 'white' }}
                                        theme={"primary-outline"}
                                        text={"Close"}
                                        onClick={() => { setShowDeleteAccount(false) }}
                                    />
                                    <CustomButton
                                        style={{ color: 'white' }}
                                        theme={'red'}
                                        text={"Delete my account"}
                                        onClick={() => deleteAccount()}
                                    />
                                </div>
                            </div>
                        </div>
                }
                <div style={{ display: "flex", height: 30, width: "100%" }} />
                {
                    // showSaveButton && <CustomButton className={styles.footerButtons}
                    //     theme={"primary-gradient"}
                    //     // text={<Text value={"Get started"} class={"capitalize"} />}
                    //     onClick={() => {
                    //         history.push('/personalInfo');
                    //         localStorage.setItem("onBoard", "1")
                    //     }}
                    // />
                }
            </div>
        </>
    );
};

export default Profile;