import React, {FunctionComponent, useMemo} from 'react';
import styles from "./cartCard.module.scss";
import {useHistory} from "react-router-dom";
import AddToCartButton from "@components/app/addToCart";

interface CartCardProps {
    id:string
    poster:string
    title:string
    price:number
    count:number
}

const CartCard:FunctionComponent<CartCardProps> = (props) => {

    const history = useHistory();

    return (
        <div
            className={`${styles.card} d-flex n w-100 gap-3 align-items-center`}
            onClick={(e)=>{
                e.stopPropagation();
                history.push(`/menu/item/${props.id}`)
            }}
        >
            <div className={`${styles.poster} d-flex rounded-1 overflow-hidden`}>
                <img src={props.poster} alt={props.title}/>
            </div>
            <div className={"d-flex justify-content-between"}>
                <div className={"d-flex flex-column"}>
                    <h1 className={"mb-2 text-capitalize"}>{props.title}</h1>
                    <p className={"bold opacity-75"}>{`total price:   €${(props.price * props.count).toFixed(1)}`}</p>
                </div>
                <AddToCartButton
                    id={props.id}
                    title={props.title}
                    price={props.price}
                    poster={props.poster}
                    initialValue={props.count}
                    transparent
                />
            </div>
        </div>
    );
};

export default CartCard;