import React from 'react';
import {Redirect, Route, Switch, useLocation} from 'react-router-dom';
import Home from "@pages/home";
import NavigationLayout from "@components/layouts/navigationLayout";
import MainLayout from "@components/layouts/mainLayout";
import SingleItem from "@pages/singleItem";
import Cart from "@pages/cart";
import Onboarding from "@pages/onboarding";
import Profile from "@pages/profile";
import Checkout from "@pages/cart/checkout";
import Slices from "@pages/slices";
import InvestSlices from "@pages/investSlices";
import Track from "@pages/track";
import Login from "@pages/login";
import PersonalInfo from "@pages/personalInfo";
import VerifyEmail from "@pages/login/verifyEmail";
import MoodDetection from "@pages/moodDetection";
import MoodDetector from '@pages/moodDetector';

import Favorites from "@pages/favorites";
import Investments from "@pages/investments";
import Donate from "@pages/slices/donate";
import BuyGift from "@pages/slices/buyGift";
import Discount from '@pages/discount';
import Notification from "@pages/notification";


const Routes = () => {

    const location = useLocation();

    return (
        <>
            <Switch>
                {/*no nav & sign out required routes*/}
                <Route exact path={[
                    "/login",
                    "/verify-email",
                ]}>
                    {
                        !localStorage.getItem('token') ? <MainLayout>
                            <Switch>
                                <Route path={'/login'} exact component={Login}/>
                                <Route path={'/verify-email'} exact component={VerifyEmail}/>
                            </Switch>
                        </MainLayout> : <Redirect to={'/'}/>
                    }
                </Route>
                {/*no nav & auth required routes*/}
                <Route exact path={[
                    "/cart/checkout",
                    "/invest",
                    "/investments",
                    "/track-orders",
                    "/personalInfo",
                    "/discount",
                    
                    "/buy-gift",
                    "/donate",
                    "/notification"
                ]}>
                    {
                        localStorage.getItem('token') ? <MainLayout>
                            <Switch>
                                <Route path={'/login'} exact component={Login}/>
                                <Route path={'/verify-email'} exact component={VerifyEmail}/>
                                <Route path={'/cart/checkout'} exact component={Checkout}/>
                                <Route path={'/invest'} exact component={InvestSlices}/>
                                <Route path={'/investments'} exact component={Investments}/> 
                                <Route path={'/track-orders'} exact component={Track}/>
                                <Route path={'/personalInfo'} exact component={PersonalInfo} />
                                <Route path={'/discount'} exact component={Discount} />
                                <Route path={'/buy-gift'} exact component={BuyGift} />
                                <Route path={'/donate'} exact component={Donate} />
                                <Route path={'/notification'} exact component={Notification} />
                            </Switch>
                        </MainLayout> : <Redirect to={`/login?ref=${location.pathname.substring(1)}`}/>
                    }
                </Route>
                {/*no nav && no auth*/}
                <Route exact path={[
                    "/onboarding",
                    "/menu/item/:id",
                    "/mood-detection",
                ]}>
                    <MainLayout>
                        <Switch>
                            <Route path={'/onboarding'} exact component={Onboarding}/>
                            <Route path={'/menu/item/:id'} exact component={SingleItem}/>
                            <Route path={'/mood-detection'} exact component={MoodDetector}/>
                        </Switch>
                    </MainLayout>
                </Route>
                {/*nav & no auth required routes*/}
                <Route exact path={[
                    "/",
                    "/cart",
                ]}>
                    <NavigationLayout>
                        <Switch>
                            <Route path={'/'} exact component={Home}/>
                            <Route path={'/cart'} exact component={Cart}/>
                        </Switch>
                    </NavigationLayout>
                </Route>
                {/*nav & auth required routes*/}
                <Route exact path={[
                    "/slices",

                    "/favorites",
                    "/profile",                    
                ]}>
                    {
                        localStorage.getItem('token') ? <NavigationLayout>
                            <Switch>
                                <Route path={'/favorites'} exact component={Favorites} />
                                {/* <Route path={'/profile'} exact component={Profile}/> */}
                                <Route path={'/slices'} exact component={Slices} />

                                <Route path={'/favorites'} exact component={Favorites} />
                                <Route path={'/profile'} exact component={Profile} />                                
                            </Switch>
                        </NavigationLayout> : <Redirect to={`/login?ref=${location.pathname.substring(1)}`}/>
                    }
                </Route>
            </Switch>
        </>
    );
};

export default Routes;
