import React, { useEffect, useState } from 'react';
import Header from "@components/layouts/layoutElements/header";
import TabBar from "@components/common/tabBar";
import Queue from "@pages/track/tabs/queue";
import InProgress from "@pages/track/tabs/inProgress";
import Ready from "@pages/track/tabs/ready";
import { Order, getOrders } from '@webservices/order';

const Track = () => {

    const [orders, setOrders] = useState<Order[]>([]);
    const [loadingOrders, setLoadingOrders] = useState<boolean>(true);

    useEffect(()=>{
        (async () => {
            const userOrders = await getOrders();
            const userOrdersSorted = [...userOrders.sort((currentOrder, otherOrder) => otherOrder.created_at.localeCompare(currentOrder.created_at))];
            setOrders(userOrdersSorted);
            setLoadingOrders(false);
        })()
    }, [])

    return (
        <>
            <Header
                title={"track orders"}
                backButton
            />
            <div className={"p-4"}>
                <TabBar
                    tabs={[
                        {
                            title:"queue",
                            content:<Queue
                                loadingOrders={loadingOrders}
                                orders={orders.filter(o=>o.orderStatus === 0)}
                            />
                        },
                        {
                            title:"in progress",
                            content:<InProgress
                                orders={orders.filter(o=>o.orderStatus === 1)}
                            />
                        },
                        {
                            title:"ready",
                            content:<Ready
                                orders={orders.filter(o=>o.orderStatus === 2)}
                            />
                        }
                    ]}
                    contentsNoPadding
                />
            </div>
        </>
    );
};

export default Track;