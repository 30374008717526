import React, {useEffect, useState} from 'react';
import Header from "@components/layouts/layoutElements/header";
import {UserSlice, getUserSlices} from "@webservices/slices";
import {getLockedPatterns, LockedPattern, Pattern} from "@webservices/patterns";
import {Slice} from "@types.d/Slice";
import LockedPatternCard from "@components/app/lockedPatternCard";
import CustomButton from "@components/common/customButton";
import Divider from "@components/common/divider";
import Icon from "@components/common/icon";
import {Loading} from "@components/common/svgIcons";

const InvestSlices = () => {

    const [userSlices, setUserSlices] = useState<Slice[]>([]);
    const [patterns, setPatterns] = useState<LockedPattern[]>([]);
    const [loadingPatterns, setLoadingPatterns] = useState<boolean>(true);

    useEffect(()=>{
        (async ()=>{
            setLoadingPatterns(true);
            const [slices, lockedPatterns] = await Promise.all([
                getUserSlices(),
                getLockedPatterns()
            ])
            setUserSlices(groupSlices(slices));//.map(slice=>({id:slice.sliceTypeId, amount:slice.amount})));
            setPatterns(lockedPatterns);
            setLoadingPatterns(false);
        })()
    }, [])

    const groupSlices = (array:UserSlice[]):Slice[] => {
        const convertedArray:{[K:string]:any} = [...array];
        let sliceTypeArray:Slice[] = [];
        array.map(item => {
            const index = sliceTypeArray.findIndex(slice => slice.id === item.sliceTypeId);
            if(index === -1){
                sliceTypeArray.push({id: item.sliceTypeId, amount: 1});
            }
            else{
                sliceTypeArray[index].amount++;
            }
        })
        return sliceTypeArray;
    }

    return (
        <div className={"h-100 d-flex flex-column"}>
            <Header
                title={"invest slices"}
                backButton
            />
            {
                !loadingPatterns ? <div className={"flex-grow-1 overflow-auto d-flex flex-column px-4"}>
                    {
                        patterns.map((pattern, index)=>{
                            return <div
                                key={index}
                                className={"d-flex flex-column align-items-center cms-back-glass rounded-4 mb-3 p-3"}
                            >
                                <LockedPatternCard pattern={pattern} userSlices={userSlices}/>
                            </div>
                        })
                    }
                </div> : <div className={"w-100 d-flex h-100 justify-content-center align-items-center"}>
                    <Icon size={30} color={"none"} icon={<Loading/>} gradient/>
                </div>
            }
        </div>
    );
};

export default InvestSlices;