import React, { ReactNode, useContext, useEffect, useState } from 'react';
import Icon from "@components/common/icon";
import Text from "@components/common/text";
import styles from "./notification.module.scss";
import { useHistory } from "react-router-dom";
import { PersonalInfo as Person, Back, Notification as Notif } from "@components/common/svgIcons";
import { getUserNotifications, setNotificationRead, UserNotification } from "@webservices/user";
import VoucherPZZA from '../../../src/assets/images/voucherPizza.png';
import { GlobalContext } from 'contexts/GlobalContext';


const Notification = () => {

    const [notifications, setNotifications] = useState<UserNotification[]|null>([]);
    const { updateNotifications } = useContext(GlobalContext);

    const history = useHistory();

    useEffect(() => {
        (async () => {
            const userNotifications = await getUserNotifications();
            setNotifications(userNotifications);
        })()
    }, [notifications])

    const generateNotificationList = () => {
        return (
            notifications?.sort((firstNote, secondNote) => secondNote.created_at.localeCompare(firstNote.created_at)).map((item) => {
                return (
                    <div className={`${styles.couponContainer}`} onClick={() => {
                            setNotificationRead(item.notificationId);
                            updateNotifications(-1);
                    }}>
                        <div className={styles.dataContainer}>
                            <div className={styles.imageContainer}>
                                <Icon size={64} color={item.readTimestamp !== null ? `${'black'}` : 'none'} icon={<Notif />} gradient />
                            </div>
                            <div className={styles.detailsContainer}>
                                <div style={{ fontWeight: 'bold' }}>
                                    <Text value={item.messageTitle} class={"capitalize"} />
                                </div>
                                <div style={{}}>
                                    <Text value={item.messageBody} class={"capitalize"} />
                                </div>
                                <div style={{ fontSize: '10px', textAlign: 'right' }}>
                                    {item.created_at.split('T')[1].substring(0, 5) + " " + item.created_at.split('T')[0].substring(2)}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })
        );
    }

    return (
        <>
            <div className={"d-flex justify-content-around flex-wrap w-100 p-3 h-100 overflow-auto"} style={{ alignItems: 'center' }}>
                <div className={styles.discountHeader}>
                    <div onClick={() => {
                        history.push('/');
                    }}>
                        <Icon size={24} color={"none"} icon={<Back />} gradient />
                    </div>
                    <h1 className={"bold primary-gradient-text f-s-6 text-center my-3"}>
                        <Text value={"Notifications"} class={"capitalize"} />
                    </h1>
                    <div></div>
                </div>
                <div className={"d-flex h-100"} style={{flexDirection: 'column'}}>
                {
                    notifications?.length === 0 ?
                            <div className={styles.noDiscount}>
                                <Text value={"You have received no notifications yet!"} class={"capitalize"} />
                            </div>                                                    
                    : generateNotificationList()
                }
                </div>
            </div>
        </>
    );
};


export default Notification;