import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from "./slicePreview.module.scss";
import {slices} from "../../../constant/slices";
import CustomButton from "@components/common/customButton";
import Text from "@components/common/text";
import { OPENSEA_COLLECTION_URL } from 'config';

interface SlicePreviewProps {
    sliceId:number
    onClose:()=>void
}

const SlicePreview:FunctionComponent<SlicePreviewProps> = (props) => {

    const [slice, setSlice] = useState<any>();

    useEffect(()=>{
        setSlice(slices.find(s=>s.id===props.sliceId));
    },[])

    return (
        <div className={styles.container}>
            <img src={slice?.icon} alt={slice?.title}/>
            <p>{slice?.title}</p>
            <div className={"d-flex gap-2 mt-3"}>
                <CustomButton
                    theme={"primary-gradient"}
                    text={<Text value={"explore on Opensea"} class={"capitalize"}/>}
                    onClick={() => {
                        window.open(`${OPENSEA_COLLECTION_URL}`);
                    }}
                />
                <CustomButton
                    theme={"primary-outline"}
                    text={<Text value={"cancel"} class={"capitalize"}/>}
                    onClick={props.onClose}
                />
            </div>
        </div>
    );
};

export default SlicePreview;