import {createContext} from "react";
import {CartItem} from "@types.d/Cart";

interface GlobalContextProps {
    cart:CartItem[]
    totalPrice:number
    unreadNotifications:number
    addToCart?:(item:CartItem)=>void
    applyDiscount:(discountedPrice:number)=>void
    updateNotifications:(numberToAdd:number)=>void
    flushCart:()=>void
}

export const GlobalContext = createContext<GlobalContextProps>({
    cart:[],
    totalPrice:0,
    unreadNotifications:0,
    applyDiscount:(discountedPrice:number)=>{},
    updateNotifications:(numberToAdd:number)=>{},
    flushCart:()=>{}
});