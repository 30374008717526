import React, {useContext, useEffect, useState} from 'react';
import MenuCard from "@components/app/menuCard";
import Header from "@components/layouts/layoutElements/header";
import {Loading, Logo} from "@components/common/svgIcons";
import {getMenuByStoreId, Menu} from "@webservices/stores/menu";
import Icon from "@components/common/icon";
import { Favorite, getFavorites } from '@webservices/favorites';
import { DEF_BRANCH } from 'config';
import { GlobalContext } from 'contexts/GlobalContext';

const Home = () => {

    const [menus, setMenus] = useState<Menu[]>([]);
    const [loadingMenu, setLoadingMenu] = useState<boolean>(true);
    const { updateNotifications } = useContext(GlobalContext);

    useEffect(()=>{
        (async () => {
            const menuItems = await getMenuByStoreId(DEF_BRANCH!);
            setMenus(menuItems);
            setLoadingMenu(false);
        })()
    }, [])

    return (
        <>
            <Header
                title={<Logo/>}
                menu
            />
            <div className={"d-flex justify-content-between flex-wrap w-100 p-3 overflow-auto h-100"}>
                {                    
                    !loadingMenu ? menus.map((item, index)=>{
                        return <div
                            className={"w-50 mb-2"}
                            style={{flexBasis: "calc(50% - 0.5rem)"}}
                            key={index}
                        >
                            <MenuCard                                                                
                                menuId={item.menuId}
                                pizzaId={item.pizzaId}
                                calories={item.calories}
                                name={item.name}
                                image={item.image}
                                details={item.details}
                                price={item.price}
                                weight={item.weight}
                            />
                        </div>
                    }) :
                        <div className={"w-100 d-flex h-100 justify-content-center align-items-center"}>
                            <Icon size={30} color={"none"} icon={<Loading/>} gradient/>
                        </div>
                }
                <div style={{display:"flex", height:30, width:"100%"}}/>
            </div>            
        </>
    );
};

export default Home;