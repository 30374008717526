import React, {FunctionComponent} from 'react';
import styles from "./mergedSlices.module.scss";
import {slices} from "../../../constant/slices";
import MissedSlice from "@assets/icons/slices/missedSlice.svg";


interface MergedSlicesProps {
    slices:(number|undefined)[]
    onSliceClick?:(id:number)=>void
}

const MergedSlices:FunctionComponent<MergedSlicesProps> = (props) => {
    return (
        <div className={styles.pattern}>
            {
                props.slices.map((slice, index)=>{
                    return <img
                        key={index}
                        src={!slice ? MissedSlice :slices[slice-1].flatIcon}
                        alt={!slice ? "" : slices[slice-1].title}
                        onClick={()=>{(slice && props.onSliceClick) && props.onSliceClick(index)}}
                    />
                })
            }
        </div>
    );
};

export default MergedSlices;