import React, {FunctionComponent, useContext, useState} from 'react';
import styles from "./header.module.scss";
import CustomButton from "@components/common/customButton";
import Icon from "@components/common/icon";
import {Back, Menu as MenuIcon, Notification} from "@components/common/svgIcons";
import {useHistory} from "react-router-dom";
import Menu from "@components/layouts/menu";
import { GlobalContext } from 'contexts/GlobalContext';

interface HeaderProps {
    menu?:boolean
    backButton?:boolean
    title?:string|React.ReactElement
}

const Header:FunctionComponent<HeaderProps> = (props) => {

    const history = useHistory();

    const [showMenu, setShowMenu] = useState<boolean>(false);
    const { unreadNotifications } = useContext(GlobalContext);

    return (
        <div className={"d-flex px-4 py-3 align-items-center justify-content-between"}>
            <div className={"d-flex align-items-center gap-2"}>
                {
                    (props.menu) && <CustomButton
                        theme={"no-border"}
                        icon={<Icon size={25} color={"none"} icon={<MenuIcon/>} gradient/>}
                        onClick={()=>setShowMenu(prevState => !prevState)}
                        className={"p-0"}
                    />
                }
                {
                    (props.backButton) && <CustomButton
                        theme={"no-border"}
                        icon={<Icon size={25} color={"none"} icon={<Back/>} gradient/>}
                        onClick={()=>history.goBack()}
                        className={"p-0"}
                    />
                }
                {
                    (props.title && typeof(props.title) === "string") ? <p className={"text-capitalize bold primary-gradient-text f-s-5"}>
                        {props.title}
                    </p> : <div className={styles.logo}>{props.title}</div>
                }
            </div>
            <div className={"d-flex align-items-center gap-2"}>
                {/* <div className={`${styles.slicesContainer} d-flex align-items-center gap-2`}>
                    <Icon size={20} color={"black"} icon={<Slice/>}/>
                    <p className={"cms-color-black"}>{0}</p>
                </div> */}
                <CustomButton
                    theme={"no-border"}
                    text={`${unreadNotifications}`}
                    textColor='primary'
                    icon={<Icon size={30} color={"none"} icon={<Notification/>} gradient/>}
                    onClick={()=>{
                        history.push('/notification');
                    }}
                    className={"p-0"}
                />
            </div>
            {
                showMenu && <Menu
                    onClose={()=>setShowMenu(false)}
                />
            }
        </div>
    );
};

export default Header;