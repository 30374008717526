import Ingredients from "@types.d/Ingredients";
import {httpGet} from "@webservices/index";
import {API_URL, PIZZA_API_URL} from "../../../../config";
import {Message} from "@webservices/Message";
import {convertUint8ArrayToBase64} from "@utilities/binary";

export interface Menu {
    menuId:string
    branchId:string
    pizzaId:string
    images:string[]
    ingredients:Ingredients[]
    weight:number
    price:number
    isActive: boolean
    created_at: string
    updated_at: string
    deleted_at: string
    name: string
    image: string
    calories: number
    currency: string
    details: string
    cookTime: number
}

export const getMenuByStoreId = async (branchId:string):Promise<Menu[]> => {
    const cachedMenu = sessionStorage.getItem("menus");
    if(cachedMenu) {
        return JSON.parse(cachedMenu) as Menu[];
    }
    const menus = await httpGet<Message<Menu[]>>(
        `${API_URL}/${PIZZA_API_URL}menus/${branchId}`,
        {noAuth:true}
    )
    if(menus?.data) {
        sessionStorage.setItem("menus", JSON.stringify(menus.data));
        return menus.data;
    }
    return [];
}

export const getMenuItem = async (id:string):Promise<Menu|undefined> => {
    const cachedMenu = sessionStorage.getItem("menus");
    if(cachedMenu) {
        return JSON.parse(cachedMenu).find((m:Menu)=>m.menuId === id);
    }
    return undefined;
}