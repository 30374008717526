import React, {useContext} from 'react';
import styles from "./cart.module.scss";
import Header from "@components/layouts/layoutElements/header";
import {GlobalContext} from "../../contexts/GlobalContext";
import Text from "@components/common/text";
import CartCard from "@components/app/cartCard";
import Switch from "@components/common/form/switch";
import CustomButton from "@components/common/customButton";
import TextArea from "@components/common/form/textArea";
import Icon from "@components/common/icon";
import {Receipt} from "@components/common/svgIcons";
import Divider from "@components/common/divider";
import {useHistory} from "react-router-dom";

const Cart = () => {

    const history = useHistory();

    const {cart, totalPrice} = useContext(GlobalContext);

    return (
        <>
            <Header
                title={"cart"}
                menu
            />
            {
                cart.length < 1  ? <div className={"d-flex h-100 w-100 justify-content-center align-items-center"}>
                    <p className={"bold opacity-75"}><Text value={"your cart is empty"} class={"capitalize"}/></p>
                </div> : <>
                    {/*if cart has item*/}
                    <div className={"d-flex flex-column flex-grow-1 overflow-auto w-100 px-4"}>
                        <h2 className={"mb-3"}><Text value={"order summary"} class={"capitalize"} /></h2>
                        <div>
                            {
                                cart.map((item, index)=>{
                                    return <div key={index} className={"mb-2"}>
                                        <CartCard {...item}/>
                                    </div>
                                })
                            }
                        </div>
                        {/* <div className={"d-flex justify-content-between align-items-center my-3"}>
                            <p>Do you want to use your slices?</p>
                            <Switch onChange={()=>{}}/>
                        </div> */}
                        <div className={styles.receipt}>
                            <div className={"d-flex gap-2 align-items-center mb-2"}>
                                <Icon size={20} color={"black"} icon={<Receipt/>}/>
                                <h2><Text value={"Receipt"} class={"capitalize"} /></h2>
                            </div>
                            <div>
                                <div className={"d-flex justify-content-between align-items-center"}>
                                    <p><Text value={"items"} class={"capitalize"} /></p>
                                    <span>3</span>
                                </div>
                                <div className={"d-flex justify-content-between align-items-center"}>
                                    <p><Text value={"total price"} class={"capitalize"} /></p>
                                    <span>€100</span>
                                </div>
                                <div className={"d-flex justify-content-between align-items-center"}>
                                    <p><Text value={"discount"} class={"capitalize"} /></p>
                                    <span>€5</span>
                                </div>
                                <div className={"d-flex justify-content-between align-items-center"}>
                                    <p><Text value={"tax"} class={"capitalize"} /></p>
                                    <span>5%</span>
                                </div>
                                <Divider/>
                                <p className={"bold f-s-5 primary-gradient-text w-100 text-center"}>€{totalPrice.toFixed(2)}</p>
                            </div>
                        </div>
                        <TextArea
                            placeHolder={"write a note here ..."}
                            onChange={()=>{}}
                            rows={6}
                        />
                    </div>
                    <div className={"d-flex w-100 justify-content-center pt-4 mb-5"}>
                        <CustomButton
                            theme={"primary-gradient"}
                            text={<span><Text value={"checkout"} class={"capitalize"}/> €{totalPrice.toFixed(2)}</span> }
                            onClick={()=>history.push("/cart/checkout")}
                        />
                    </div>
                </>
            }
        </>
    );
};

export default Cart;