import React, {FunctionComponent, useEffect, useState} from 'react';
import UserSlices from "@components/app/userSlices";
import CustomButton from "@components/common/customButton";
import MergedSlices from "@components/app/mergedSlices";
import { v1 as uid } from 'uuid';
import {Slice} from "@types.d/Slice";
import {failToast} from "@toast/index";
import Text from "@components/common/text";

interface SelectSliceProps{
    onSubmit: (slices:Slice[]) => void
    hideMe: () => void
}

const SelectSlice:FunctionComponent<SelectSliceProps> = (props) => {

    const [patternSlices, setPatternSlices] = useState<(number|undefined)[]>([]);
    const [selectedCount, setSelectedCount] = useState<number>(0);
    const [release, setRelease] = useState<{id:number, amount:number, actionId:string}>();

    useEffect(()=>{
        const slices:(number|undefined)[] = [];
        for (let i=1; i<9; i++){
            slices.push(undefined);
        }
        setPatternSlices(slices);
    }, [])

    const handleSliceSelect = (id:number, status:"lock"|"unlock") => {
        console.log(id);
        if(selectedCount >= 8 || status==="lock") return;
        setPatternSlices(prevState => {
            const temp = [...prevState];
            const index = temp.findIndex(t=>t===undefined);
            temp[index] = id;
            return temp;
        })
        setSelectedCount(prevState=>prevState+1)
    }

    const handleSubmit = () => {
        if(patternSlices.includes(undefined)){
            failToast(<Text value={"please select 8 slices."} class={"capitalize"}/>)
            return;
        }
        
        
        const slices:Slice[] = [];
        patternSlices.map(slice => {
            //#region : uncomment after Fucking David
            const index = slices.findIndex(p => p.id === slice);
            if(index !== -1){
                slices[index].amount ++;
            } else {
                 slices.push({id:slice!, amount:1});
            }
            //#endregion : uncomment after Fucking David
        })
        props.onSubmit(slices);
    }

    const handleSliceRemove = (id:number) => {
        setRelease({id: patternSlices[id]!, amount: 1, actionId: uid()})
        setPatternSlices(prevState => {
            const temp = [...prevState];
            temp[id] = undefined;
            return temp;
        })
        setSelectedCount(prevState => prevState - 1);
    }

    return (
        <div>
            <p>Please select a slice to complete  pizza</p>
            <div className={"d-flex w-100 justify-content-center my-3"}>
                <div style={{height:250, width:250}}>
                    <MergedSlices
                        slices={patternSlices}
                        onSliceClick={handleSliceRemove}
                    />
                </div>
            </div>
            <UserSlices 
                disabled={ selectedCount >= 8 }
                onSelect={handleSliceSelect}                
                decreaseOnSelect
                release={release}
            />
            <div className={"d-flex gap-2 justify-content-center mt-3"}>
                <CustomButton
                    text={"cancel"}
                    onClick={() => props.hideMe()}
                />
                <CustomButton
                    text={"continue"}                    
                    theme={"primary-gradient"}
                    disabled={selectedCount < 8 }
                    onClick={handleSubmit}
                />
            </div>
        </div>
    );
};

export default SelectSlice;