import React, {FunctionComponent} from 'react';
import CustomButton from "@components/common/customButton";
import {Google, MetaMask, WalletConnect} from "@components/common/svgIcons";
import {useHistory} from "react-router-dom";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { onUserLoggedIn } from 'services/app/userServices';

interface AuthMethodsProps {
    gmail?:boolean
    metaMask?:boolean
    connectWallet?:boolean
}

const AuthMethods:FunctionComponent<AuthMethodsProps> = (props) => {

    const history = useHistory();

    const auth = getAuth();

    const gmailLogin = async () => {
        try{
            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(auth, provider);
            await GoogleAuthProvider.credentialFromResult(result);
            const user = auth.currentUser;
            if(user){
                const convertedResult = user as {[key:string]:any}
                await onUserLoggedIn({
                    token: convertedResult.accessToken,
                    uid: convertedResult.uid,
                    email:convertedResult.email,
                    newUser:false
                });
            }
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div
            className={"d-flex align-items-center justify-content-center gap-3 bold"}
        >
            <div className={"d-flex gap-3"}>
                {
                    props.gmail && <CustomButton
                        theme={"no-border"}
                        icon={<Google/>}
                        className={"p-0"}
                        onClick={gmailLogin}
                    />
                }
                {
                    props.connectWallet && <CustomButton
                        theme={"no-border"}
                        icon={<WalletConnect/>}
                        className={"p-0"}
                        onClick={()=>history.push("/auth/login")}
                    />
                }
                {
                    props.metaMask && <CustomButton
                        theme={"no-border"}
                        icon={<MetaMask/>}
                        className={"p-0"}
                    />
                }
            </div>
        </div>
    );
};

export default AuthMethods;