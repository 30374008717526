import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from "./menuCard.module.scss";
import Icon from "@components/common/icon";
import {Euro, Fire, Heart, HeartOutline, Loading} from "@components/common/svgIcons";
import AddToCartButton from "@components/app/addToCart";
import {useHistory} from "react-router-dom";
import CustomButton from "@components/common/customButton";
import {addToFavorites, getFavorites, removeFromFavorites} from "@webservices/favorites";

interface MenuCardProps {
    menuId:string
    pizzaId:string
    image:string
    name:string
    details:string
    calories:number
    weight:number
    price:number
}

const MenuCard:FunctionComponent<MenuCardProps> = (props) => {

    const history = useHistory();

    const [isFavorite, setIsFavorite] = useState<boolean>(false);
    const [favoriteId, setFavoriteId] = useState<string>();
    const [favoriteLoading, setFavoriteLoading] = useState<boolean>(false);

    useEffect(()=>{     
        const favorites = localStorage.getItem("favorites");        
        if(!favorites) 
            return;
        const favoritesObj = JSON.parse(favorites) as [any];
        const index = favoritesObj.findIndex(f => f.pizzaId === props.pizzaId);
        if (index !== -1) {
            setIsFavorite(true);
            setFavoriteId(favoritesObj[index].pizzaFavoriteId);
        }
    }, [])

    const addPizzaToFavorites = async () => {
        const userId = localStorage.getItem("sub");
        if (userId === null)
            history.push('/login');
        else {
            setFavoriteLoading(true);

            if (favoriteId === undefined) {
                const res = await addToFavorites(props.pizzaId);
                setIsFavorite(true);
                setFavoriteId(res?.pizzaFavoriteId);
            }
            else {
                await removeFromFavorites(favoriteId!);
                const favorites = localStorage.getItem("favorites");
                if (favorites) {
                    const favoritesObj = JSON.parse(favorites!) as [any];
                    localStorage.setItem("favorites", JSON.stringify(favoritesObj.filter(f => f.pizzaId !== props.pizzaId)));
                }
                setIsFavorite(false);
                setFavoriteId(undefined);
            }

            await getFavorites();
            setFavoriteLoading(false);
        }
    }

    return (
        <div
            className={`${styles.card} d-flex flex-column w-100`}
            onClick={(e)=>{
                e.stopPropagation();
                history.push(`/menu/item/${props.menuId}`)
            }}
        >
            <div className={"d-flex w-100 rounded-1 overflow-hidden position-relative"}>
                    <CustomButton
                        theme={isFavorite ? "red-favorites" :  "white"}
                        icon={
                            isFavorite ?
                                <Icon size={20} color={"red"} icon={<Heart />} /> :
                                <Icon size={20} color={"white"} icon={<HeartOutline />} />
                        }
                        loading={favoriteLoading}
                        loadingIcon={<Icon size={20} color={"none"} icon={<Loading />} gradient />}
                        style={{ position: "absolute", right: 10, top: 10 }}
                        className={"px-1"}
                        onClick={addPizzaToFavorites}
                    />
                <img src={props.image} alt={props.name}/>
            </div>
            <h1 className={"my-2 text-capitalize"}>{props.name}</h1>
            <div className={"d-flex align-items-center gap-2 mb-2"}>
                <Icon size={18} color={"none"} icon={<Fire/>} gradient/>
                <p className={"bold opacity-75 f-s-2"}>{`${props.calories}Cal | ${props.weight}gr`}</p>
            </div>
            <p>{props.details}</p>
            <div className={"d-flex flex-column justify-content-between align-items-start gap-3"}>
                <div className={"d-flex align-items-center gap-1"}>
                    <Icon size={15} color={"none"} icon={<Euro/>} gradient/>
                    <p className={"bold primary-gradient-text f-s-7"}>{props.price.toFixed(1)}</p>
                </div>
                <AddToCartButton
                    id={props.menuId}
                    title={props.name}
                    price={props.price}
                    poster={props.image}
                />
            </div>
        </div>
    );
};

export default MenuCard;