import React, {FunctionComponent, useState} from 'react';
import styles from './trackingCard.module.scss';
import Icon from "@components/common/icon";
import {Clock, Down, Tick, Up} from "@components/common/svgIcons";
import CustomButton from "@components/common/customButton";
import Pepperoni from "@assets/images/pepperoni.png";

interface TrackingCardProps {
    name:string
    lastUpdateTime:string
    status:number
}

const TrackingCard:FunctionComponent<TrackingCardProps> = (props) => {

    const orderStatuses = [
        {title:"order places", description:"Order received. Appreciate it!", status:0},
        {title:"payment confirmed", description:"Payment processed. You're all set!", status:1},
        // {title:"order confirmed", description:"Order confirmed. Thank you!", status:2},
        // {title:"order processing", description:"Pizza baking away. It's almost there!", status:3},
        {title:"ready to pickup", description:"Pizza is ready to satisfy. Enjoy every bite!", status:2},
    ]

    const [collapse, setCollapse] = useState<boolean>(true);

    return (
        <div className={styles.container}>
            <div className={"w-100 d-flex justify-content-between align-items-center"}>
                <div className={"d-flex gap-3 align-items-center"}>
                    <img className={styles.icon} src={Pepperoni} alt={"pep"}/>
                    <div className={"d-flex flex-column"}>
                        <h1>{props.name}</h1>
                        <p className={"w-100 d-flex gap-2 mt-2"}>
                            <Icon size={20} color={"black"} icon={<Clock/>}/>
                            {props.lastUpdateTime}
                        </p>
                        <p className={"mt-2"}>ID: rE2r3a45</p>
                    </div>
                </div>
                <CustomButton
                    onClick={()=>{setCollapse(prevState => !prevState)}}
                    theme={"no-border"}
                    icon={<Icon size={15} color={"primary"} icon={collapse ? <Down/> : <Up/>}/>}
                    className={"p-0"}
                />
            </div>
            <div className={`${styles.steps} ${collapse ? styles.collapse : ""}`}>
                <div className={styles.verticalLine}/>
                {
                    orderStatuses.reverse().map((status, index)=>{
                        return <div
                            className={`d-flex gap-3 mt-3 ${status.status === props.status ? "opacity-100" : "opacity-50"}`}
                            key={index}
                        >
                            <Icon size={20} color={"black"} icon={<Tick/>}/>
                            <div>
                                <h2>{status.title}</h2>
                                <p>{status.description}</p>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    );
};

export default TrackingCard;