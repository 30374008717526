import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from "./lockedPatternCard.module.scss";
import {claimPattern, LockedPattern} from "@webservices/patterns";
import MergedSlices from "@components/app/mergedSlices";
import {Slice} from "@types.d/Slice";
import Icon from "@components/common/icon";
import {Dollar2, Face, Like, Similarity} from "@components/common/svgIcons";
import {slices} from "../../../constant/slices";
import Divider from "@components/common/divider";
import CustomButton from "@components/common/customButton";
import Modal from "@components/common/modal";
import ConfirmModal from "@components/modals/confirmModal";
import { OPENSEA_COLLECTION_URL } from 'config';

interface LockedPatternCardProps {
    pattern:LockedPattern
    userSlices:Slice[]
}

const LockedPatternCard:FunctionComponent<LockedPatternCardProps> = (props) => {

    const [missedSlices, setMissedSlices] = useState<Slice[]>([]);
    const [matchedSlices, setMatchSlices] = useState<(number|undefined)[]>([]);
    const [similarity, setSimilarity] = useState<number>(0);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [claimLoading, setClaimLoading] = useState<boolean>(false);
    const [enlargePattern, setEnlargePattern] = useState<boolean>(false);

    useEffect(()=>{
        const missed:Slice[] = [];
        const matched:(number|undefined)[] = [];
        const userSlices = [...props.userSlices];
        let matchedCount = 0;
        props.pattern.patternSlices.map(slice=>{
            //find user owned slice index by current id
            const userSliceIndex = userSlices.findIndex(s=>s.id === slice.sliceId && s.amount > 0);
            if(userSliceIndex === -1) {
                // the user does not own this slice
                missed.push({id:slice.sliceId, amount:slice.amount});

                for(let i=0; i<slice.amount; i++){
                    matched.push(slice.sliceId)
                }
            } else {
                // the user owns this slice
                let existSlices:number;
                // calculate difference of user balance with required slices
                const diff = slice.amount - userSlices[userSliceIndex].amount;
                if(diff > 0){
                    // the user does not have sufficient slices
                    // so add difference to missed slices
                    missed.push({id:slice.sliceId, amount:diff});
                    for(let i=0; i<diff; i++){
                        matched.push(undefined)
                    }
                    // add existing to matched slices
                    existSlices = userSlices[userSliceIndex].amount;
                } else {
                    existSlices = slice.amount;
                }
                for(let i=0; i<existSlices; i++){
                    matched.push(slice.sliceId);
                    userSlices[userSliceIndex] = {id:slice.sliceId, amount:userSlices[userSliceIndex].amount-1};
                    matchedCount++;
                }
            }
        });
        setMissedSlices(missed);
        setMatchSlices(matched);
        setSimilarity(matchedCount*100/8)
    }, [])

    const requestForClaim = async () => {
        setClaimLoading(true);
        await claimPattern(props.pattern.patternId);
        setClaimLoading(false);
        setClaimLoading(false);
    }

    return (
        <div>
            <div className={"d-flex align-items-center"}>
                <div className={styles.pattern} onClick={() => {setEnlargePattern(true)}}>
                    <MergedSlices
                        slices={matchedSlices}
                    />
                </div>
                <div className={styles.statics}>
                    <div className={`${styles.staticsItem} mb-3`}>
                    <span className={"d-flex gap-2"}>
                        <Icon size={20} color={"black"} icon={<Similarity/>}/>
                        <p className={"bold"}>{similarity}%</p>
                    </span>
                        <p className={" f-s-2"}>Similarity</p>
                    </div>
                    <div className={`${styles.staticsItem} mb-3`}>
                    <span className={"d-flex gap-2"}>
                        <Icon size={20} color={"black"} icon={<Like/>}/>
                        <p className={"bold"}>{props.pattern.customerRate}</p>
                    </span>
                        <p className={" f-s-2"}>Customers rate</p>
                    </div>
                    <div className={styles.staticsItem}>
                    <span className={"d-flex gap-2"}>
                        <Icon size={20} color={"black"} icon={<Dollar2/>}/>
                        <p className={"bold"}>{props.pattern.revenueRate}</p>
                    </span>
                        <p className={" f-s-2"}>Revenue Rate</p>
                    </div>
                    <div className={styles.staticsItem}>
                    <span className={"d-flex gap-2"}>
                        <Icon size={20} color={"black"} icon={<Face/>}/>
                        <p className={"bold"}>{props.pattern.peopleBeforeYou}</p>
                    </span>
                        <p className={" f-s-2"}>Before you</p>
                    </div>
                </div>
            </div>
            {
                missedSlices[0] && <>
                    <p className={"my-3"}>You need these slice to complete this pattern. Tap yo buy.</p>
                    <div className={"d-flex w-100 justify-content-center gap-1"}>
                        {
                            missedSlices.map((slice, index)=>{                                
                                return <div key={index} className={styles.missed} onClick={() => {window.open(`${OPENSEA_COLLECTION_URL}`);}}>
                                    <img src={slices[slice.id-1].icon} alt={slices[slice.id-1].title}/>
                                    <p>x{slice.amount}</p>
                                </div>
                            })
                        }
                    </div>
                </>
            }
            <Divider/>
            <div className={"d-flex w-100 gap-2 justify-content-center"}>
                <CustomButton
                    text={"more info"}
                />
                <CustomButton
                    theme={"primary-gradient"}
                    text={"claim"}
                    className={"flex-grow-1"}
                    style={missedSlices[0] ? {opacity:0.5} : {}}
                    onClick={missedSlices[0] ? undefined : ()=>{setShowConfirmModal(true)}}
                />
            </div>
            {
                showConfirmModal && <Modal
                    align={"center"}
                    onClose={()=>{setShowConfirmModal(false)}}
                >
                    <ConfirmModal
                        title={"Are you sure to claim your slice ?"}
                        confirmText={"yes, claim"}
                        onConfirm={requestForClaim}
                        onCancel={()=>{setShowConfirmModal(false)}}
                        inProgress={claimLoading}
                    />
                </Modal>
            }
            {
                enlargePattern && <Modal align={"center"} closeOnOutSideClick={true}
                    onClose={() => { setEnlargePattern(false) }}
                >
                    <div className={styles.enlargedPatternContainer}>
                        <div className={styles.enlargedPattern}>
                            <MergedSlices
                                slices={matchedSlices}
                            />
                        </div>
                    </div>
                </Modal>
            }
        </div>
    );
};

export default LockedPatternCard;