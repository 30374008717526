import React, {FunctionComponent} from 'react';
import {
    Basil,
    Cheese, Chicken, Garlic, Meat,
    Mushroom,
    Olives,
    Onion,
    Salt,
    Sausage,
    Shrimp,
    Tomato
} from "@components/common/svgIcons";
import HorizontalSlider from "@components/basic/horizontalSlider";
import Icon from "@components/common/icon";
import IngredientsType from "@types.d/Ingredients";
import Text from "@components/common/text";
import Color from "@types.d/CmsColors";

interface IngredientsProps {
    values:IngredientsType[]
}

const Ingredients:FunctionComponent<IngredientsProps> = (props) => {

    const ingredients:{[K:string]:{icon:React.ReactElement, color:Color}} = {
        "olives":{icon:<Olives/>, color:"green"},
        "onions":{icon:<Onion/>, color:"black"},
        "salt":{icon:<Salt/>, color:"black"},
        "tomato":{icon:<Tomato/>, color:"red"},
        "basil":{icon:<Basil/>, color:"green"},        
        "cheese":{icon:<Cheese/>, color:"yellow"},
        "sausage":{icon:<Sausage/>, color:"blue"},
        "shrimp":{icon:<Shrimp/>, color:"black"},
        "mushrooms":{icon:<Mushroom/>, color:"red"},
        "garlic":{icon:<Garlic/>, color:"black"},
        "chicken":{icon:<Chicken/>, color:"yellow"},
        "meat":{icon:<Meat/>, color:"red"},
    }

    return (
        <div> 
            <h1 className={"mb-3 opacity-75"}><Text value={"ingredients"} class={"capitalize"}/></h1>
            <HorizontalSlider
                slideWidth={45}
                slides={                    
                    props.values.map((ingredient, index)=>{                        
                        return <div key={index} className={"d-flex flex-column align-items-center"} style={{width: '60px'}}>
                            <Icon
                                size={30}
                                color={ingredients[ingredient.toLowerCase()]?.color || "black"}
                                icon={ingredients[ingredient.toLowerCase()]?.icon || <Tomato/>}
                            />
                            <p className={`mt-1 text-capitalize text-center cms-color-${ingredients[ingredient]?.color || "black"}`} style={{fontSize: '10px', color: `${ingredients[ingredient.toLowerCase()]?.color}`}}>
                                {ingredient}
                            </p>
                        </div>
                    })}
            />
        </div>
    );
};

export default Ingredients;