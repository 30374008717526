import React, {FunctionComponent, useEffect, useState} from 'react';
import Web3 from 'web3'
import TextArea from "@components/common/form/textArea";
import CustomButton from "@components/common/customButton";
import Icon from "@components/common/icon";
import {Loading, MetaMask} from "@components/common/svgIcons";
import {failToast} from "@toast/index";
import Text from "@components/common/text";
import {updateWalletId} from "@webservices/user";
import { NETWORK_ID } from 'config';

interface ConnectWalletProps {
    onSubmit:(address:string)=>void
    onClose:()=>void
}

const ConnectWallet:FunctionComponent<ConnectWalletProps> = (props) => {

    const networks:{[K:string]:string} = {
        "137":"Polygon Mainnet",
        "80001":"Mumbai Testnet"
    }

    const [walletId, setWalletId]  = useState<string>("");
    const [loading, setLoading]  = useState<boolean>(false);

    useEffect(()=>{
        (async () => {
            if (typeof window.ethereum !== 'undefined') {
                const web3 = new Web3(window.ethereum);
                try {
                    const chainId = await window.ethereum.request({ method: 'eth_chainId' });
                    const convertedChainId = web3.utils.hexToNumber(chainId);
                    if(convertedChainId !== NETWORK_ID){
                        try {
                            await window.ethereum.request({
                                method: 'wallet_switchEthereumChain',
                                params: [{ chainId: web3.utils.numberToHex(NETWORK_ID!)}],
                            });
                        } catch (switchError:any) {
                            // This error code indicates that the chain has not been added to MetaMask.
                            if (switchError.code === 4902) {
                                failToast(`${networks[String(NETWORK_ID)]} is not available in your metamask, please add it`)
                            }
                            console.log("Failed to switch to the network")
                        }
                    }
                } catch (error) {
                    console.error(error);
                }
            } else {
                failToast(<Text value={'Web3 provider not detected. Please install Metamask or enable it.'}  class={'capitalize'}/>);
            }
        })()
    }, [])

    const getIdFromWeb3 = async () => {
        // Check if Web3 is available
        if (typeof window.ethereum !== 'undefined') {
            new Web3(window.ethereum);
            try {
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                setWalletId(accounts[0]);
            } catch (error) {
                console.error(error);
            }
        } else {
            failToast(<Text value={'Web3 provider not detected. Please install Metamask or enable it.'}  class={'capitalize'}/>);
        }
    }

    const applyWalletId = async () => {
        if(walletId){
            setLoading(loading)
            await updateWalletId(walletId);
            props.onSubmit(walletId);
        }
    }

    return (
        <div className={"d-flex flex-column align-items-center p-4"}>
            <p className={"text-center"}>To invest in or sell your NFT, you need to enter your wallet address. Please note that you can add only one wallet, and you will not be able to change it later. If you lose your wallet, all your assets will be lost.</p>
            <div
                onClick={getIdFromWeb3}
                className={"d-flex align-items-center gap-2 my-4"}
            >
                <Icon size={30} color={"none"} icon={<MetaMask/>}/>
                <p className={"f-s-5"}>Connect Metamask</p>
            </div>
            <TextArea
                placeHolder={"wallet address"}
                default={walletId}
                onChange={(id, value)=>{setWalletId(value || "")}}
                className={"mb-3 w-100"}
                rows={5}
                disable
            />
            <div className={"d-flex gap-3 w-100 justify-content-center"}>
                <CustomButton
                    text={"close"}
                    theme={"primary-outline"}
                    onClick={props.onClose}
                />
                <CustomButton
                    text={"submit"}
                    theme={"primary-gradient"}
                    loading={loading}
                    loadingIcon={<Icon size={20} color={"none"} icon={<Loading/>}/>}
                    onClick={applyWalletId}
                />
            </div>
        </div>
    );
};

export default ConnectWallet;