import React, {useContext, useEffect, useState} from 'react';
import styles from "./singleItem.module.scss";
import CustomButton from "@components/common/customButton";
import Text from "@components/common/text";
import Icon from "@components/common/icon";
import {Back, Clock, Euro, Fire, StarFill} from "@components/common/svgIcons";
import {useHistory, useParams} from "react-router-dom";
import AddToCartButton from "@components/app/addToCart";
import {getMenuItem, Menu} from "@webservices/stores/menu";
import {GlobalContext} from "../../contexts/GlobalContext";
import Ingredients from "@components/app/ingredients";

const SingleItem = () => {

    const history = useHistory();

    let { id } = useParams<{id: string}>();

    const { totalPrice } = useContext(GlobalContext);

    const [itemDetails, setItemDetails] = useState<Menu>();

    /**
     * load item details on load
     */
    useEffect(()=>{
        (async () => {
            const item = await getMenuItem(id);
            setItemDetails(item);
        })()
    }, [])

    return (
        <>
            <div className={`${styles.header} d-flex p-4`}>
                <div onClick={()=>history.goBack()}>
                    <Icon size={25} color={"none"} icon={<Back/>} gradient/>
                </div>
            </div>
            {
                itemDetails && <div>
                    <div className={`${styles.topImage} d-flex w-100 overflow-hidden`}>
                        <img className={"w-100"} src={itemDetails.image} alt={""}/>
                    </div>
                    <div className={"d-flex flex-column px-4"}>
                        <h1 className={"my-2 text-capitalize"}>{itemDetails.name}</h1>
                        <div className={"d-flex align-items-center gap-2 mb-2"}>
                            <Icon size={18} color={"none"} icon={<Fire/>} gradient/>
                            <p className={"bold opacity-75 f-s-2"}>{`${itemDetails.calories}Cal | ${itemDetails.weight}gr`}</p>
                        </div>
                        <div className={"d-flex justify-content-between align-items-center"}>
                            <div className={"d-flex align-items-center gap-1"}>
                                <Icon size={15} color={"none"} icon={<Euro/>} gradient/>
                                <p className={"bold primary-gradient-text f-s-7"}>{(itemDetails.price).toFixed(1)}</p>
                            </div>
                            <AddToCartButton
                                id={id}
                                title={itemDetails.name}
                                price={itemDetails.price}
                                poster={itemDetails.image}
                            />
                        </div>
                        <div className={`${styles.infoBox} d-flex w-100 justify-content-between my-3`}>
                            <div className={"d-flex flex-column align-items-center"}>
                                <div className={"d-flex gap-1 mb-2"}>
                                    <Icon size={15} color={"yellow"} icon={<StarFill/>}/>
                                    <p className={"bold"}>4.5</p>
                                </div>
                                <p><Text value={"rating"} class={"capitalize"}/></p>
                            </div>
                            <div className={"d-flex flex-column align-items-center"}>
                                <div className={"d-flex gap-1 mb-2"}>
                                    <Icon size={15} color={"yellow"} icon={<Clock/>}/>
                                    <p className={"bold"}>70</p>
                                </div>
                                <p><Text value={"reviews"} class={"capitalize"}/></p>
                            </div>
                            <div className={"d-flex flex-column align-items-center"}>
                                <div className={"d-flex gap-1 mb-2"}>
                                    <Icon size={15} color={"yellow"} icon={<Clock/>}/>
                                    <p className={"bold"}>30</p>
                                </div>
                                <p><Text value={"min"} class={"capitalize"}/></p>
                            </div>
                        </div>
                        <Ingredients values={itemDetails.ingredients}/>
                        <h1 className={"mt-3 mb-2 opacity-75"}><Text value={"details"} class={"capitalize"}/></h1>
                        <p>Pepperoni is a variety of spicy salami made from cured pork and beef seasoned with paprika or other chili pepper.</p>
                    </div>
                </div>
            }
            <div className={`${styles.actionBtn} d-flex justify-content-between w-100 p-3`}>
                <div className={"d-flex align-items-center gap-1"}>
                    <Icon size={15} color={"none"} icon={<Euro/>} gradient/>
                    <p className={"bold primary-gradient-text f-s-7"}>{(totalPrice).toFixed(2)}</p>
                </div>
                <CustomButton
                    theme={"primary-gradient"}
                    text={<Text value={"go to cart"} class={"capitalize"}/>}
                    style={{minWidth:180}}
                    onClick={()=>history.push("/cart")}
                />
            </div>
        </>

    );
};

export default SingleItem;