import React, {FunctionComponent} from 'react';
import styles from "./unlockedPatternCard.module.scss";
import {Pattern} from "@webservices/patterns";
import MergedSlices from "@components/app/mergedSlices";

interface LockedPatternCardProps {
    pattern:Pattern
}

const UnlockedPatternCard:FunctionComponent<LockedPatternCardProps> = (props) => {
    return (
        <div>
            <div className={"d-flex align-items-center"}>
                <div className={styles.pattern}>
                    <MergedSlices
                        slices={props.pattern.patternSlices.map(s=>(s.sliceId))}
                    />
                </div>
            </div>
        </div>

    );
};

export default UnlockedPatternCard;