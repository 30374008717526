import React, {FunctionComponent, useEffect, useState} from 'react';
import {GlobalContext} from "../contexts/GlobalContext";
import {CartItem} from "@types.d/Cart";
import { getUserNotifications } from '@webservices/user';



const GlobalProvider:FunctionComponent<any> = (props) => {

    const [cart, setCart] = useState<CartItem[]>([]);
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [unreadNotifications, setUnreadNotifications] = useState<number>(0);

    useEffect(()=>{
        const stringCartItems = localStorage.getItem("cart");
        if(stringCartItems) {
            setCart(JSON.parse(stringCartItems));
        }
        (async () => {
            const userNotifications = await getUserNotifications(()=>{});
            updateNotifications(userNotifications?.filter(item => item.readTimestamp === null).length || 0);
        })()
    }, [])

    const addToCart = (item:CartItem) => {
        const newCart = [...cart];
        const itemIndex = cart.findIndex(c=>c.id === item.id);
        if(itemIndex === -1){
            if(item.count !== 0) newCart.push(item);
        } else {
            if(item.count !== 0) {
                newCart[itemIndex] = item;
            } else {
                newCart.splice(itemIndex, 1);
            }
        }
        const totalExpense = newCart.reduce((acc, item) => acc + (item.count * item.price), 0);
        setTotalPrice(totalExpense);   
        localStorage.setItem("cart", JSON.stringify(newCart));
        setCart(newCart);
    }

    const applyDiscount = (discountedPrice:number) => {
        setTotalPrice(discountedPrice);
    }

    const flushCart = () => {
        setCart([]);
        localStorage.setItem("cart", JSON.stringify([]));
    }

    const updateNotifications = (numberToAdd:number) => {
        setUnreadNotifications(unreadNotifications + numberToAdd);
    }

    return (
        <GlobalContext.Provider value={{
            cart,
            totalPrice,
            unreadNotifications,
            addToCart,
            applyDiscount,
            updateNotifications,
            flushCart
        }}>
            {props.children}
        </GlobalContext.Provider>
    );
};

export default GlobalProvider;