import React, {useContext, useEffect, useState} from 'react';
import Routes from "./routes";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/globals.scss';
import LanguageProvider from "@components/layouts/languageProvider";
import {initiateFirebase, messaging} from "./services/firebaseLoader";
import {getAuth, RecaptchaVerifier} from "firebase/auth";
import {useHistory} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {ApiToken} from "@types.d/ApiToken";
import GlobalProvider from "./providers/globalProvider";
import {onMessage} from "@firebase/messaging";
import {successToast} from "@toast/index";
import Text from "@components/common/text";
import { GlobalContext } from 'contexts/GlobalContext';
import { getUserNotifications } from '@webservices/user';
import { ContractCodeNotStoredError } from 'web3';

declare global {
    interface Window {
        recaptchaVerifier: RecaptchaVerifier
        cancelTokens:ApiToken[]
        ethereum:any
    }
}
window.recaptchaVerifier = window.recaptchaVerifier || {};
window.cancelTokens = window.cancelTokens || [];
window.ethereum = window.ethereum || [];

const App = () => {

    const history = useHistory();
    const [checkId, setCheckId] = useState<string>("");
    const { updateNotifications } = useContext(GlobalContext);

    onMessage(messaging, (payload) => {        
        successToast(<Text value={payload.notification?.body || "Unknown Message"} class={"capitalize"}/>);
        updateNotifications(+1);
    });

    useEffect(()=>{

        // initiate firebase
        initiateFirebase();

        let theme = localStorage.getItem('theme');
        if(!theme) theme = 'dark-theme';
        const root = document.getElementById('root');
        if(!root) return;
        root.className = theme;
        root.className = theme;

        getAuth().onAuthStateChanged(function(user) {
            if (user) {
                user.getIdToken(true).then(token=>{
                    localStorage.setItem("token", token);
                })                
            } else {
                console.log('User is signed out or token has expired');
            }
        });

    }, [])

    return (
        <GlobalProvider>
            <LanguageProvider>
                <div id={"main"}>
                    <ToastContainer style={{padding:"0.5rem"}}/>
                    <div id={"selectSheet"} className={"selectSheet bottomSheets"}></div>
                    <div id={"bottomSheets"} className={"bottomSheets"}></div>
                    <div id={"portals"} className={"portals"}/>
                    <Routes />
                </div>
            </LanguageProvider>
        </GlobalProvider>
    );
};

export default App;