import React, { useState } from 'react';
import Icon from "@components/common/icon";
import Text from "@components/common/text";
import styles from "./donate.module.scss";
import { useHistory } from "react-router-dom";
import { Back, Loading } from "@components/common/svgIcons";
import CustomButton from '@components/common/customButton';
import { donateSlices } from '@webservices/slices';
import {Slice} from "@types.d/Slice";
import BottomSheet from "@components/common/bottomSheet";
import SelectSlice from "@components/bottomSheets/selectSlice";
import Modal from "@components/common/modal";
import Confetti from "@assets/images/confetti.svg";

const Donate = () => {

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [selectedSlicesBT, setSelectedSliceBT] = useState<boolean>(false);

    const history = useHistory();

    const donate = (slices:Slice[]) => {
        (async () => {
            const releaseResult = await donateSlices(slices);
            setSelectedSliceBT(false);
            setShowConfirmation(true);
        })()
    }

    return (
        <>
            <div className={`"d-flex flex-wrap w-100 p-4 overflow-auto" ${styles.donateMain}`}>
                <div className={styles.donateHeader}>
                    <div onClick={() => {
                        history.push('/slices');
                    }}>
                        <Icon size={24} color={"none"} icon={<Back />} gradient />
                    </div>
                    <h1 className={"bold primary-gradient-text text-center my-3"} style={{ paddingLeft: '20px' }}>
                        <Text value={"donate"} class={"capitalize"} />
                    </h1>
                </div>
                <div className={"d-flex mt-5"} style={{ flexGrow: '1', textAlign: 'center'}}>
                    <Text value={" Here is how you can contribute in #BetterWorld activity in PZZA app."} class={"capitalize"} />
                    <Text value={" We use your donations in various ways, to reduce hunger and world reach out the Zero Hunger."} class={"capitalize"} />
                    <Text value={" To learn more check #BetterWorld or PZZA website. www.aiqo.ai"} class={"capitalize"} />
                </div>
                <div className='mt-5 d-flex justify-content-between flex-column align-items-center'>
                    <CustomButton
                        className={styles.buttonSave}
                        theme={"primary-gradient"}
                        loadingIcon={<Icon size={20} color={"none"} icon={<Loading />} />}
                        text={<Text value={"Donate"} class={"capitalize"} />}
                        onClick={() => setSelectedSliceBT(true)}
                    />
                </div>
                {
                    showConfirmation && <Modal
                        align={"center"}
                        onClose={()=> {
                            setShowConfirmation(false);
                            history.push('/slices');
                        }}
                        backgroundImage={Confetti}
                    >
                        <div className={styles.messageBox}>
                            <Text value={"Great! Your donation is on its way to make the world a better place to live in #BetterWorld activity."} class={"capitalize"} />
                            <CustomButton
                                className={styles.buttonSave}
                                theme={"primary-gradient"}
                                loadingIcon={<Icon size={20} color={"none"} icon={<Loading />} />}
                                text={<Text value={"Ok"} class={"capitalize"} />}
                                onClick={() => {
                                    history.push('/slices');
                                }}
                            />
                        </div>
                    </Modal>
                }
            </div>
            {
                selectedSlicesBT && <BottomSheet
                    onClose={()=>setSelectedSliceBT(false)}
                    floatHeight
                >
                    <SelectSlice
                        hideMe={()=>setSelectedSliceBT(false)}
                        onSubmit={donate}
                    />
                </BottomSheet>
            }
        </>
    );
};


export default Donate;