import React, {FunctionComponent} from 'react';
import Icon from "@components/common/icon";
import {MoodDetection} from "@components/common/svgIcons";
import CustomButton from "@components/common/customButton";
import Text from "@components/common/text";
import {useHistory} from "react-router-dom";

interface MoodDetectionModalProps {
    onClose:()=>void
}

const MoodDetectionModal:FunctionComponent<MoodDetectionModalProps> = (props) => {

    const history = useHistory();

    return (
        <div className={"d-flex flex-column p-4 justify-content-center align-items-center"}>
            <Icon size={30} color={"green"} icon={<MoodDetection/>}/>
            <p className={"text-center my-3"}>Would you like us to prepare your pizza based on your emotions?</p>
            <div className={"d-flex gap-2"}>
                <CustomButton
                    theme={"primary-outline"}
                    text={<Text value={"not now"} class={"capitalize"}/>}
                    onClick={props.onClose}
                />
                <CustomButton
                    theme={"primary-gradient"}
                    text={<Text value={"yes, try!"} class={"capitalize"}/>}
                    onClick={()=>history.push("/mood-detection")}
                />
            </div>
        </div>
    );
};

export default MoodDetectionModal;