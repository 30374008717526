import React, {FunctionComponent, useEffect, useState} from 'react';
import TrackingCard from "@components/app/trackingCard";
import {getOrders, Order} from "@webservices/order";
import Icon from "@components/common/icon";
import {Loading} from "@components/common/svgIcons";

interface QueueProps {
    orders:Order[]
    loadingOrders?:boolean
}

const Queue:FunctionComponent<QueueProps> = (props) => {
    return (
        <div className={"h-100"}>
            {
                !props.loadingOrders ? props.orders.map((order, index)=>{
                    return <TrackingCard
                        name={"Order " + order.orderId.split('-')[0]}
                        lastUpdateTime={order.created_at}
                        status={order.orderStatus}
                    />
                }) : <div className={"w-100 d-flex h-100 justify-content-center align-items-center"}>
                    <Icon size={30} color={"none"} icon={<Loading/>} gradient/>
                </div>
            }
        </div> 
    );
};

export default Queue; 