import React, { useEffect, useState } from 'react';
import Icon from "@components/common/icon";
import Input from "@components/common/form/input";
import Text from "@components/common/text";
import styles from "./buyGift.module.scss";
import { useHistory } from "react-router-dom";
import {Envelop, Back, Loading, ScanBarcode} from "@components/common/svgIcons";
import CustomButton from '@components/common/customButton';
import buyGift from '@assets/images/buyGift.png';
import buyGiftLine from '@assets/images/buyGiftLine.png';
import Html5QrcodePlugin from '@components/common/qrScanner';
import BottomSheet from '@components/common/bottomSheet';
import {giftSlices} from '@webservices/slices';
import SelectSlice from "@components/bottomSheets/selectSlice";
import {Slice} from "@types.d/Slice";
import {failToast, successToast} from "@toast/index";
import { getUserInfo } from '@webservices/user';
import QRCode from 'react-qr-code';


const BuyGift = () => {

    const [showQRCode, setShowQRCode] = useState(false);
    const [email, setEmail] = useState<string|undefined>();
    const [myEmail, setMyEmail] = useState<string|undefined>();
    const [selectedSlicesBT, setSelectedSliceBT] = useState<boolean>(false);
    
    const history = useHistory();

    useEffect(() => {
        (async()=>{
            const userInfo = await getUserInfo();
            setMyEmail(userInfo?.email);
            console.log(myEmail);
        })()
    }, [])
    
    const sendGift = (slices:Slice[]) => {
        (async () => {
            if(!email){
                setSelectedSliceBT(false);
                failToast(<Text value={"please enter valid email address"} class={"capitalize"}/>);
                return;
            }
            const releaseResult = await giftSlices(slices, email);
            successToast(<Text value={"The gift has been sent successfully to your friend."} class={"capitalize"}/>);
            history.push('/slices');
        })()
    }

    return ( 
        <>
            <div className={"d-flex justify-content-around flex-wrap w-100 h-100 p-4"} style={{flexDirection:'column', alignItems: 'center', overflow: 'scroll' }}>
                <div className={styles.buyGiftHeader}>
                    <div onClick={() => {
                        history.push('/slices');
                    }}>
                        <Icon size={24} color={"none"} icon={<Back />} gradient />
                    </div>
                    <h1 className={"bold primary-gradient-text text-center my-3"} style={{paddingLeft: '20px'}}>
                        <Text value={"Buy gift"} class={"capitalize"} />
                    </h1>
                </div>
                <img src={buyGift} alt="" />
                <div className={"mt-4"} style={{textAlign: 'center'}}>
                    <Text value={"You can make your friends happy by sending them a PZZA gift."} class={"capitalize"} />
                    <Text value={"Simply enter their email address and we announce them about your love for them."} class={"capitalize"} />
                </div>
                <div className={"w-100 mt-4"}>
                    <Input
                        onClick={()=>{}}
                        label={"Friend's email"}
                        labelStyle={{ paddingLeft: '17px' }}
                        placeHolder={"Enter your friend's email"}
                        default={email}
                        onChange={(id, value) => {
                            setEmail(value);
                        }}
                        preIcon={<Icon size={25} color={"none"} icon={<Envelop />} gradient />}
                        button={<CustomButton
                            className={styles.buttonSend}
                            theme={"primary-gradient"}                            
                            loadingIcon={<Icon size={20} color={"none"} icon={<Loading/>}/>}
                            text={<Text value={"Send"} class={"capitalize"} />}
                            onClick={() => setSelectedSliceBT(true)}
                        />}
                    />
                </div>
                <div className={"w-95 mt-4"}>
                    <img src={buyGiftLine} alt="" />
                </div>
                {
                    showQRCode && <BottomSheet onClose={() => { setShowQRCode(false) }}>
                        <Html5QrcodePlugin
                            onScan={(value) => {
                                console.log(value);
                                setEmail(value);
                                setShowQRCode(false);
                            }}
                            onError={() => {}}
                        />
                    </BottomSheet>
                }
                <div className='mt-5 d-flex justify-content-between flex-column align-items-center' style={{ height: '75px' }}>
                    <Text value={"or scan the QRCode"} />
                    <CustomButton
                        className={styles.buttonSave}
                        theme={"primary-gradient"}
                        loadingIcon={<Icon size={20} color={"none"} icon={<Loading />} />}
                        icon={<Icon size={24} color={"none"} icon={<ScanBarcode />} />}
                        text={<Text value={"Scan"} class={"capitalize"} />}
                        onClick={() => { setShowQRCode(true) }}
                    />
                </div>
                
                {                    
                    myEmail &&
                    <div className='mt-2 d-flex justify-content-between flex-column align-items-center' style={{ height: '75px' }}>
                        <Text value={"send my gift here"} />
                        <div style={{margin: '7px', padding: '5px', backgroundColor: 'white'}}>
                        <QRCode 
                            id="qrCode"
                            value={myEmail!}
                            size={150}
                            bgColor={"white"}
                            fgColor={"black"}
                        />
                        </div>
                    </div>
                }
                {
                    selectedSlicesBT && <BottomSheet
                        onClose={() => setSelectedSliceBT(false)}
                        floatHeight
                    >
                        <SelectSlice
                            hideMe={() => setSelectedSliceBT(false)}
                            onSubmit={sendGift}
                        />
                    </BottomSheet>
                }                
            </div>            
        </>
    );
};


export default BuyGift;